import { Image } from '@naan/primitives/image';
import { styled } from 'naan/stitches.config';

export const Figure = styled(Image, {
  width: '196px',
  height: 'auto',
  borderRadius: '8px',
  '@medium': {
    width: '100%',
  },
});

export const FigureContainer = styled('div', {
  gridColumn: '1/-1',
  display: 'flex',
  justifyContent: 'center',
  marginTop: '$space$6',
  marginBottom: '$space$5',
  '@medium': {
    aspectRatio: 1,
    gridColumn: '-5/-1',
    marginTop: '$space$6',
    marginBottom: '0',
    // backgroundColor: '$green-100',
  },
});

export const Content = styled('div', {
  gridColumn: '1/-1',
  '@medium': {
    gridColumn: '1/-5',
    gridRow: '1',
    // backgroundColor: '$blue-100',
  },
  '@extraLarge': {
    gridColumn: '1/-6',
    gridRow: '1',
    // backgroundColor: '$blue-100',
  },
});

export const Header = styled('header', {
  display: 'grid',
  gridTemplateColumns: '1fr 40px',
  '& > *': {
    gridColumn: 1,
  },
  // temporal
  '& > .tools': {
    gridColumn: 2,
    position: 'absolute',
    right: 0,
    top: 0,
    width: 40,
    height: 40,
  },
  '@small': {
    flex: 1,
  },
});

export const Meta = styled('div', {});
