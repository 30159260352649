import * as React from 'react';
import { CouponExtraLargeIcon } from 'naan/icons/coupon-icon';
// import { DownloadExtraLargeIcon } from 'naan/icons/download-icon';
import { EduExtraLargeIcon } from 'naan/icons/edu-icon';
import { LifebuoyIcon } from 'naan/icons/lifebuoy-icon';
import { styled } from 'naan/stitches.config';
import { useCouponDialog } from '../coupons/use-coupon-dialog';
import { useNavigate } from 'react-router-dom';
import { AppFactory } from 'app/app-factory';
// import { noAuthRedirect } from 'components/nav/path-helpers';
import { useAnonymousCouponDialog } from 'components/coupons/use-anonymous-coupon-dialog';
import { clearSavedScrollPosition } from 'lib/scroll-utils/deep-scroll-restoration';
import { profilePath, supportPath } from 'components/nav/path-helpers';
import { observer } from 'mobx-react';

import __ from 'core/lib/localization';

const LinkWrapper = styled('a', {
  $$iconColor: '$colors$teal-500',
  display: 'flex',
  alignItems: 'center',
  background: '$colors$gray-25',
  padding: '16px 20px',
  borderRadius: '12px',
  cursor: 'pointer',
  transition: 'background .15s',
  textDecoration: 'none',

  '&:hover': {
    background: '$colors$gray-50',
  },

  '& > .icon': {
    marginRight: 20,
    color: '$$iconColor',
    '& > svg': {
      width: 40,
      height: 40,
    },
  },

  '& > .label': {
    color: '$textSecondary',
    // textAlign: 'center',
  },

  '@small': {
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '40px 24px',
    marginBottom: '0',
    '& > .icon': {
      margin: '0 0 8px 0',
    },
    '& > .label': {
      textAlign: 'center',
    },
  },
});

const QuickLink = ({
  label,
  icon,
  ...props
}: {
  label: string;
  icon: React.ReactNode;
} & React.ComponentProps<typeof LinkWrapper>) => {
  return (
    <LinkWrapper {...props}>
      <span className="icon">{icon}</span>
      <span className="label">{label}</span>
    </LinkWrapper>
  );
};

/// DRY is just a pipe dream
// const ExternalQuickLink = ({
//   label,
//   icon,
//   ...props
// }: {
//   label: string;
//   icon: React.ReactNode;
// } & React.ComponentProps<typeof LinkWrapper>) => {
//   return (
//     <LinkWrapper {...(props as any)} as={ExternalLink}>
//       <span className="icon">{icon}</span>
//       <span className="label">{label}</span>
//     </LinkWrapper>
//   );
// };

const Wrapper = styled('div', {
  display: 'grid',
  gap: '8px',
  '@small': {
    gridTemplateColumns: 'repeat(auto-fit, minmax(30%, 1fr))',
    gap: '16px',
  },
});

const CodeQuickLink = () => {
  const handleApplyCode = useCouponDialog();

  return (
    <QuickLink
      href="#"
      css={{ $$iconColor: '$colors$yellow-600' }}
      icon={<CouponExtraLargeIcon />}
      label={__('Have a code?', 'haveACode')}
      onClick={e => {
        e.preventDefault();
        handleApplyCode();
      }}
    />
  );
};

const AnonymousCodeQuickLink = () => {
  const showInterstitialCouponDialog = useAnonymousCouponDialog();

  return (
    <QuickLink
      href="#"
      css={{ $$iconColor: '$colors$yellow-600' }}
      icon={<CouponExtraLargeIcon />}
      label={__('Have a code?', 'haveACode')}
      onClick={e => {
        e.preventDefault();
        showInterstitialCouponDialog();
      }}
    />
  );
};

const HelpQuickLink = observer(() => {
  const navigate = useNavigate();

  const navigateToSupport = React.useCallback(
    (e: any /* todo: properly type*/) => {
      e.preventDefault();
      navigate(supportPath());
    },
    [navigate]
  );

  return (
    <QuickLink
      href={supportPath()}
      css={{ $$iconColor: '$colors$orange-500' }}
      icon={<LifebuoyIcon />}
      label={__('Support and feedback', 'supportAndFeedback')}
      onClick={navigateToSupport}
    />
  );
});

const ClassroomQuickLink = () => {
  const navigate = useNavigate();
  // const { authenticated } = AppFactory.root.userManager;

  return (
    <QuickLink
      href="/app/profile/schools"
      css={{ $$iconColor: '$colors$gray-800' }}
      icon={<EduExtraLargeIcon />}
      label={__('Jiveworld for schools', 'jiveworldForSchools')}
      onClick={e => {
        const to = `${profilePath()}/schools`;
        clearSavedScrollPosition(to as string);
        e.preventDefault();
        navigate(to);
      }}
    />
  );
};

export const QuickLinks = () => {
  const { classroomEnabled, purchaseFlowDisabled, authenticated } =
    AppFactory.root.userManager;

  return (
    <Wrapper>
      {purchaseFlowDisabled ? null : authenticated ? (
        <CodeQuickLink />
      ) : (
        <AnonymousCodeQuickLink />
      )}
      <HelpQuickLink />
      {classroomEnabled || purchaseFlowDisabled ? null : <ClassroomQuickLink />}
    </Wrapper>
  );
};
