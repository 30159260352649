import * as React from 'react';
import { styled } from '@naan/stitches.config';
import { observer } from 'mobx-react';
import { subscribePath } from 'components/nav/path-helpers';
import { PlainMarkdown } from '@naan/primitives/text';
import { Link, useNavigate } from 'react-router-dom';

import __ from '@core/lib/localization';
import { track } from '@app/track';
import { ReturnNavState } from 'components/nav/return-nav-state';

const Wrapper = styled('div', {
  marginTop: 24,
  marginBottom: 12,
  padding: '0 16px',
  textAlign: 'center',
  color: '$textSecondary',
  '& a': {
    color: '$teal-500',
    textDecoration: 'underline',
  },
  '&.dark': {
    color: '$white',
    '& a': {
      color: '$teal-300',
    },
  },
});

type PlayerType = 'study' | 'soundbites';

// function getString(
//   playerType: PlayerType,
//   url: string
// ): [string: string, stringIndex: number] {
//   const strings: Record<PlayerType, string[]> = {
//     soundbites: [
//       __(
//         '[Subscribe](%{url}) to unlock all stories and Soundbites',
//         'suscribeToUnlockAllStoriesAndSoundbites',
//         {
//           url,
//         }
//       ),
//     ],
//     study: [
//       __(
//         '[Subscribe](%{url}) to unlock all stories and Soundbites',
//         'suscribeToUnlockAllStoriesAndSoundbites',
//         {
//           url,
//         }
//       ),
//     ],
//   };

//   const stringIndex = Math.floor(Math.random() * strings[playerType].length);
//   return [strings[playerType][stringIndex], stringIndex];
// }

export const EndCardCta = observer(
  ({
    playerType,
    listenMode = false,
    backTo,
  }: {
    playerType: PlayerType;
    listenMode?: boolean;
    backTo: string;
  }) => {
    // const [string, stringIndex] = getString(
    //   playerType,
    //   subscribePath({ backTo, flow: playerType + '_endCard' })
    // );
    // // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    // stringIndex; // just to avoid lint errors for WIP code so I can commit and save progress

    const navigate = useNavigate();
    // beware, this `backTo` property driven flow here throws a wrench into our other logic which
    // decides when it's safe to pop from the story detail page, when attempting the 'back' action
    const url = subscribePath({ backTo, flow: playerType + '_endCard' });
    const string = __(
      '[Subscribe](%{url}) to unlock all stories',
      'suscribeToUnlockAllStories',
      {
        url,
      }
    );
    return (
      <Wrapper className={listenMode ? 'dark' : ''}>
        <PlainMarkdown
          source={string}
          components={{
            a: ({ node, ...props }) => (
              <Link
                to={props.href}
                {...props}
                onClick={e => {
                  e.preventDefault();
                  // hack to prevent popping back to subscription page from story detail
                  ReturnNavState.storyListVisited = false;
                  navigate(props.href, { replace: true });
                  track('endcard__subscrdibe_clicked', { playerType });
                }}
              />
            ),
          }}
        />
      </Wrapper>
    );
  }
);
