import * as React from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { Story } from '@core/models/story-manager/story';
import { styled } from '@naan/stitches.config';
import { learnStoryPath } from 'components/nav/path-helpers';
import { StoryTitle } from 'components/stories/story-title';
import { MarkSimple } from '@naan/primitives/text';
import { Image } from '@naan/primitives/image';

const Wrapper = styled(Link, {
  all: 'unset',
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'column',
  '& > .thumbnail-image': {
    width: '100%',
    borderRadius: 8,
    marginBottom: 12,
    aspectRatio: '1',
    outline: '1px solid rgba(0,0,0,.06)',
    outlineOffset: -1,
  },
  '& > .title': {
    display: 'block',
    textStyle: 'small-heading',
  },
  '& > .channel': {
    display: 'block',
    color: '$textSecondary',
    textStyle: 'small-text-bold',
    marginBottom: 4,
  },
  '& > .tagline': {
    display: 'block',
    color: '$textSecondary',
    textStyle: 'small-text',
  },
});

export const StoryMinicard = observer(({ story }: { story: Story }) => {
  return (
    <Wrapper to={learnStoryPath(story)} title={story.title}>
      <Image
        src={story.listImageUrl}
        alt={story.title}
        className="thumbnail-image"
        style={{ backgroundColor: story.themeColor }}
      />
      <StoryTitle story={story} />
      {story.channel ? (
        <span className="channel">{story.channel.title}</span>
      ) : null}
      <span className="tagline">
        <MarkSimple source={story.tagline} />
      </span>
    </Wrapper>
  );
});
