import React from 'react';
// import { useMediaQuery } from 'react-responsive';
import { styled } from '@naan/stitches.config';
import { StudentProgress } from '@core/models/user-manager';
import {
  BookmarkCheckmarkIcon,
  BookmarkFilledIcon,
} from '@naan/icons/bookmark-icon';
import classNames from 'classnames';

// import __ from 'core/lib/localization';

const VocabBadge = styled('div', {
  '.pill': {
    userSelect: 'none',
    background: '$colors$transparent',
    // color: '$colors$white',
    paddingInline: 7,
    paddingBlock: 5,
    borderRadius: 8,
    textAlign: 'center',
    border: '1px solid $colors$black-alpha-10',
    display: 'inline-flex',
    gap: 6,
    textStyle: 'body-bold',

    '& > div': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      color: '$colors$purple-500',
      '& .icon': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& svg': {
          height: 20,
          width: 20,
        },
      },

      '&.learned': {
        color: '$colors$green-500',
      },

      '&.is-zero': {
        color: '$colors$gray-300',
      },
    },
    '@extraSmallOnly': {
      marginTop: 8,
    },
  },
});

export const VocabCountCell = ({
  studentProgress,
}: {
  studentProgress: StudentProgress;
}) => {
  // const isMobile = useMediaQuery({
  //   query: '(max-device-width: 550px)',
  // });

  const { vocabCount, learnedVocabCount } = studentProgress;

  return (
    <VocabBadge>
      <div className="pill">
        <div className={classNames('count', { 'is-zero': vocabCount === 0 })}>
          <span className="icon">
            <BookmarkFilledIcon />
          </span>
          <span className="label">{vocabCount}</span>
        </div>
        <div
          className={classNames('learned', {
            'is-zero': learnedVocabCount === 0,
          })}
        >
          <span className="icon">
            <BookmarkCheckmarkIcon />
          </span>
          <span className="label">{learnedVocabCount}</span>
        </div>
      </div>
    </VocabBadge>
  );
};
