import * as React from 'react';
import { styled } from 'naan/stitches.config';
import { CheckmarkHeavyIcon } from 'naan/icons/checkmark-heavy-icon';

const Wrapper = styled('div', {
  $$labelColor: '$colors$textSecondary',
  $$hintColor: '$colors$textSecondary',
  input: {
    display: 'none',
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    userSelect: 'none',
  },
  '& .fake-checkbox': {
    height: '28px',
    width: '28px',
    left: '0px',
    top: '0px',
    borderRadius: '4px',
    border: '1px solid $gray-100',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& svg': {
      height: '20px',
      width: '20px',
      color: '$green-500',
    },
    marginRight: '$space$3',
  },
  '& .label': {
    textStyle: 'body',
    color: '$$labelColor',
    padding: '2px 0',
    '& .hint': {
      display: 'block',
      color: '$$hintColor',
      textStyle: 'small-text',
    },
  },

  variants: {
    presentation: {
      settings: {
        $$labelColor: '$colors$textPrimary',
        label: {
          alignItems: 'flex-start',
        },
      },
    },
  },
});

type WrapperProps = React.ComponentProps<typeof Wrapper>;

export const Checkbox = ({
  label,
  checked,
  onChange,
  hint,
  ...props
}: {
  label: React.ReactNode;
  checked: boolean;
  onChange: () => void;
  hint?: React.ReactNode;
} & WrapperProps) => {
  return (
    <Wrapper {...props}>
      <label>
        <input type="checkbox" checked={checked} onChange={onChange} />
        <div className="fake-checkbox">
          {checked ? <CheckmarkHeavyIcon /> : null}
        </div>
        <span className="label">
          {label}
          {hint ? <span className="hint">{hint}</span> : null}
        </span>
      </label>
    </Wrapper>
  );
};
