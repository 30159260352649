import * as React from 'react';
import { observer } from 'mobx-react';
import { styled } from '@naan/stitches.config';
import { Button, IconButton } from '@naan/primitives/button';
import { ExportIcon } from '@naan/icons/export-icon';
// import { Responsive } from '@naan/primitives/responsive';
import { presentVocabExportDialog } from '../export-vocab-dialog';
import { useCurrentStory } from '../current-story-context';
import { Box } from '@naan/primitives/box';

import __ from '@core/lib/localization';

const HeadingWrapper = styled('div', {
  background: '$purple-700',
  color: '$white',
  padding: '$2 0',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  '&  .left': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    '&  .title': {
      marginLeft: '$4',
      textStyle: 'small-heading',
    },
    '&  .count': {
      marginLeft: '$2',
      textStyle: 'small-text',
    },
  },
  '&  .right': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '&  .button': {
      marginLeft: 'auto',
    },
  },
});

export const VocabListHeading = observer(
  ({
    studentName,
    count,
    onClose,
  }: {
    studentName?: string;
    count: number;
    onClose: () => void;
  }) => {
    const story = useCurrentStory();
    const studentMode = !!studentName;
    const titlePrefix = studentName ? `${studentName}: ` : '';
    return (
      <HeadingWrapper>
        <div className="left">
          <span className="title">
            {titlePrefix}
            {__('Vocabulary', 'vocabulary')}
          </span>
          <span className="count">
            {__('%{count} items', 'countItems', { count })}
          </span>
        </div>
        <div className="right">
          {!studentMode && story.progressMayBeNull?.showVocabExportUi ? (
            <Box css={{ marginLeft: '12px' }}>
              <IconButton
                presentation={'whiteTransparent'}
                icon={<ExportIcon />}
                onClick={() => {
                  presentVocabExportDialog(story);
                }}
                size="small"
                testId="present-export-vocab-button"
              />
            </Box>
          ) : null}
          <div className="button">
            <Button
              onClick={onClose}
              label={__('Close', 'close')}
              presentation="whiteTransparent"
            />
          </div>
        </div>
      </HeadingWrapper>
    );
  }
);
