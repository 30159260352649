import React from 'react';
import { chunk } from 'lodash';
import cx from 'classnames';
import { MarkSimple } from '@naan/primitives/text';
import { Notation } from '@tikka/client/client-aliases';
import { styled } from '@naan/stitches.config';

import __ from 'core/lib/localization';

const Wrapper = styled('div', {
  '& .vocabs, .sics': {
    '--background-color': 'var(--green400)',
    backgroundColor: 'var(--background-color)',
    borderRadius: '8px',
    padding: '20px 0',

    '@media print, screen and (min-width: 700px)': {
      display: 'flex',
    },

    '& h5': {
      font: 'var(--body-font)',
      fontWeight: '600',
      padding: '0 16px',
      marginBottom: '16px',
      flex: '2',
      '@media print, screen and (min-width: 700px)': {
        marginBottom: '0',
      },
    },

    '& ul': {
      padding: '0 16px',
      flex: '5',
      '&.single': {
        flex: '10',
      },
    },

    '& li': {
      margin: '0',
      padding: '0',
      lineHeight: '24px',
      '& div': {
        display: 'inline',
      },
      '& div p': {
        display: 'inline',
        font: 'var(--body-font)',
        lineHeight: '24px',
      },
      '& .term-note p': {
        color: 'var(--text-secondary)',
        '& *': {
          color: 'inherit',
        },
      },
    },
  },

  '& .sics': {
    marginTop: '24px',
    '--background-color': 'var(--lighter-orange)',
  },
});

const clean = (word: string) => {
  if (!word) return '';
  return word.replace(/[*+]/g, '');
};

const Columns = ({
  notations,
  className = 'vocabs',
  id,
  title,
}: {
  notations: Notation[];
  className?: string;
  id: string;
  title: string;
}) => {
  if (!notations?.length) {
    return null;
  }
  const columns =
    notations.length > 1
      ? chunk(notations, Math.ceil(notations.length / 2))
      : [notations];

  return (
    <div className={className} id={id}>
      <h5>{title}</h5>
      {columns.map((column, i) => {
        return (
          <ul className={cx({ single: notations.length === 1 })} key={i}>
            {column.map(item => {
              const headword = clean(item.headword);
              const termText = item.usageText
                ? `${item.usageText} (${headword})`
                : headword;

              return (
                <li key={termText}>
                  <span className="term-text">
                    <MarkSimple source={termText} />
                  </span>
                  {item.note ? (
                    <>
                      {/* &nbsp; */}
                      <div className="term-note">
                        <MarkSimple source={`– ${item.note}`} />
                      </div>
                    </>
                  ) : null}
                </li>
              );
            })}
          </ul>
        );
      })}
    </div>
  );
};

export const Glossary = ({
  notations,
  id,
}: {
  notations: Notation[];
  id: string;
}) => {
  return (
    <Wrapper>
      <Columns
        title={__('Vocab', 'vocab')}
        notations={notations}
        id={`${id}-vocab`}
      />
    </Wrapper>
  );
};
