import { AlertIcon } from 'naan/icons/alert-icon';
import { CheckmarkHeavyIcon } from 'naan/icons/checkmark-heavy-icon';
import { InfoCircleIcon } from 'naan/icons/info-circle-icon';
import { styled } from 'naan/stitches.config';
import * as React from 'react';

// TODO: remove this once we figure out how are going to slice and share the different
// notification services. I'm inlining it for now, to avoid having a hard dependency on
// the notification service.
type TNotificationAction = {
  label: string;
  callback: () => void;
};

const ToastWrapper = styled('div', {
  $$iconColor: '$colors$green-500',
  background: '$gray-800',
  display: 'flex',
  left: '0px',
  top: '0px',
  borderRadius: '8px',
  alignSelf: 'flex-start',
  '& > .left': {
    cursor: 'default',
    padding: '$4',
    display: 'flex',
    '& > .label': {
      color: '$white',
    },
    '& > .icon': {
      marginRight: '$2',
      color: '$$iconColor',
      lineHeight: 0,
    },
  },
  '& > button': {
    all: 'unset',
    textStyle: 'body-bold',
    color: '$white',
    background: '$gray-800',
    py: '$4',
    px: '$5',
    borderTopRightRadius: '8px',
    borderBottomRightRadius: '8px',
    borderLeft: '1px solid $gray-600',
    transition: 'all 0.3s ease-in-out',
    '&:hover': {
      background: '$gray-700',
    },
  },
  variants: {
    type: {
      info: {
        $$iconColor: '$colors$white',
      },
      success: {
        $$iconColor: '$colors$green-500',
      },
      warning: {
        $$iconColor: '$colors$yellow-500',
      },
      error: {
        $$iconColor: '$colors$red-500',
      },
    },
  },
});

type ToastProps = React.ComponentProps<typeof ToastWrapper> & {
  label: React.ReactNode;
} & {
  onDismiss?: () => void;
  action?: TNotificationAction;
};

export const Toast = ({ type, label, action, onDismiss }: ToastProps) => {
  const icon = React.useMemo(() => {
    switch (type) {
      case 'info':
        return <InfoCircleIcon />;
      case 'success':
        return <CheckmarkHeavyIcon />;
      case 'warning':
        return <AlertIcon />;
      case 'error':
        return <AlertIcon />;
      default:
        break;
    }
    return null;
  }, [type]);

  return (
    <ToastWrapper type={type}>
      <div className="left" onClick={onDismiss}>
        <span className="icon">{icon}</span>
        <span className="label">{label}</span>
      </div>
      {action ? (
        <button onClick={action.callback}>{action.label}</button>
      ) : null}
    </ToastWrapper>
  );
};
