import * as React from 'react';
import { styled } from 'naan/stitches.config';
import { BarChart } from '../bar-chart';
import { TotalPoints } from '../total-points';
import { AppFactory } from 'app/app-factory';
import { observer } from 'mobx-react';
import { BarchartZeroState } from './bar-chart-zero-state';

import __ from 'core/lib/localization';

const Container = styled('div', {
  position: 'relative',
  display: 'flex',
  width: '100%',
  minHeight: 280,
  flexDirection: 'column',
  justifyContent: 'space-between',
  borderRadius: '12px',
  backgroundColor: '$gray-25',
  padding: '$space$5 $space$6 $space$4 $space$6',
  '& > .zero-message': {
    position: 'absolute',
    inset: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    textStyle: 'small-heading',
    color: '$colors$blue-600',
    '& a': {
      color: '$colors$teal-500',
    },
  },
  '& > .weeks': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});

const WeekPoints = styled('div', {
  // marginBottom: '$space$10',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  '& > .title': {
    textStyle: 'small-heading',
  },
  '&:first-child': {
    alignItems: 'flex-start',
    opacity: 0.5,
  },
});

export const PointsProgressChart = observer(() => {
  const { userData } = AppFactory.root.userManager;
  const viewData = userData.statsChartData;
  const showZeroState = viewData.highestDayPoints === 0;

  return (
    <Container>
      <div className="weeks">
        <WeekPoints>
          <div className="title">{__('Last week', 'lastWeek')}</div>
          <TotalPoints points={viewData.lastWeekTotalPoints} />
        </WeekPoints>
        <WeekPoints>
          <div className="title">{__('This week', 'thisWeek')}</div>
          <TotalPoints points={viewData.thisWeekTotalPoints} />
        </WeekPoints>
      </div>
      {showZeroState ? (
        <div className="zero-message">
          <BarchartZeroState />
        </div>
      ) : null}

      <BarChart viewData={viewData} />
    </Container>
  );
});
