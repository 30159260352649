import * as React from 'react';
import { styled } from '@naan/stitches.config';
import { paletteKey } from 'study/views/study-palette';
import { KeyboardIcon } from './keyboard-icon';
import { openKeyboardOverlay } from './keyboard-toggles';

// (window as any).openKeyboardOVerlay = openKeyboardOverlay;
// (window as any).closeKeyboardOverlay = closeKeyboardOverlay;

const KeyboardButtonWrapper = styled('div', {
  display: 'inline-flex',
  padding: 8,
  justifyContent: 'center',
  alignItems: 'center',
  gap: 8,
  borderRadius: 8,
  color: paletteKey('$$keyboardButton__iconColor'),

  // svg: {
  //   width: 23,
  //   height: 12,
  // },
  '&:hover': {
    backgroundColor: paletteKey('$$keyboardButton__hoverBackground'),
  },
});

export const KeyboardHelpButton = () => {
  return (
    <KeyboardButtonWrapper onClick={openKeyboardOverlay}>
      <span className="button-inner">
        <KeyboardIcon />
      </span>
    </KeyboardButtonWrapper>
  );
};
