import * as React from 'react';
import { AppFactory } from '@app/app-factory';
import { Button } from '@naan/primitives/button';
import { styled } from '@naan/stitches.config';
import { getStreakMessage, StreakMessageType } from './streak-messages';
import { observer } from 'mobx-react';
import { AnimatePresence, motion } from 'framer-motion';

import bg from './bg.svg';
import wave from './streak-wave.svg';

import __ from '@core/lib/localization';
import { daysCount } from '@core/lib/localization-helpers';

const Flame = ({ size = 136, fill = '#F5832B' }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 136 136"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M98.4677 29.456C143.362 85.9475 104.292 120.246 79.1457 130.334C103.475 89.8622 82.3381 63.1823 68.7287 54.9013C76.2559 69.3554 68.3927 86.1181 63.5202 92.6928C63.6546 82.9362 58.0876 74.5749 55.2873 71.6139C56.1274 83.6589 47.3905 92.9939 45.5423 104.888C44.0638 114.404 50.8628 125.817 54.4472 130.334C-4.23753 107.56 22.5934 64.9579 26.5003 58.7545L26.5564 58.6654C29.5135 53.9678 31.5969 43.3581 32.2689 38.6404C36.839 45.9879 39.4377 51.6391 40.1657 53.5462C52.6662 41.0193 57.8075 16.4072 58.8157 5.66699C78.1712 10.485 89.8428 36.0808 93.2592 48.2765C97.2916 44.3016 98.4117 34.0733 98.4677 29.456Z"
      fill={fill}
    />
  </svg>
);

// const wave = (
//   <svg
//     width={1416}
//     height={72}
//     viewBox="0 0 1416 72"
//     fill="none"
//     xmlns="http://www.w3.org/2000/svg"
//   >
//     <path
//       d="M-188 0C-496 0 -496 64 -804 64V72H1660V64C1352 64 1352 0 1044 0C736 0 736 64 428 64C120 64 120 0 -188 0Z"
//       fill="white"
//     />
//   </svg>
// );

const Wrapper = styled(motion.div, {
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  top: 0,
  background: 'white',
  display: 'grid',
  gridTemplateRows: '1fr auto',
  zIndex: 1, // this is just so it's above the bottom nav
  '& > .streak-area': {
    overflow: 'hidden',
    background: `transparent url(${bg}) no-repeat center/cover`,
    // backgroundSize: 'cover',
    display: 'grid',
    placeItems: 'center',
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: '-1px',
      background: `transparent url(${wave}) no-repeat bottom center/101%`,
    },
    '& > .streak': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      '& > .streak-flame': {
        marginBottom: 8,
      },
      '& > .streak-text': {
        textStyle: 'large-heading',
      },
    },
    '& > .wave': {
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      '& svg': {
        // width: '100%',
        // height: 72,
      },
    },
  },
  '& > .copy-area': {
    $$paddingBottom: '16px',
    '@media (min-width: 448px)': {
      $$paddingBottom: '32px',
    },

    '& .inner-column': {
      paddingTop: 64,
      paddingBottom: 'calc($$paddingBottom + var(--sab))',
      width: 'min(100% - 32px, 400px)',
      margin: '0 auto',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      '& > .message': {
        textAlign: 'center',
        marginBottom: 64,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 4,

        '& > blockquote': {
          textStyle: 'medium-heading',
        },

        '& > cite': {
          all: 'unset',
          textAlign: 'center',
          '& > span': {
            textStyle: 'serif',
          },
        },
      },
    },
  },
});

export const StreakInterstitial = observer(() => {
  const {
    userData,
    streakInterstitialEverShown,
    recordStreakInterstitialShow,
  } = AppFactory.root.userManager;
  const { currentStreak } = userData;
  const firstEver = !streakInterstitialEverShown;

  const interstitialMessageType: StreakMessageType = firstEver
    ? 'firstEver'
    : currentStreak === 1
    ? 'dayOne'
    : 'other';

  const message = getStreakMessage(interstitialMessageType);
  // const show = userData.streakShowState === 'SHOW_INTERSTITIAL';
  const show = userData.showStreakInterstitial;

  const onDismiss = React.useCallback(() => {
    userData.stopShowingStreakInterstitial();
    recordStreakInterstitialShow();
  }, [userData, recordStreakInterstitialShow]);

  return (
    <AnimatePresence>
      {show === true && (
        <Wrapper
          initial={{ y: '100%' /* opacity: 0 */ }}
          animate={{ y: 0 /* opacity: 1 */ }}
          transition={{ ease: 'easeOut', duration: 0.3 }}
          exit={{ y: '100%' /* opacity: 0 */ }}
          className="streak-interstitial"
        >
          <div className="streak-area">
            <div className="streak">
              <div className="streak-flame">
                <Flame />
              </div>
              <span className="streak-text">{daysCount(currentStreak)}</span>
            </div>
            {/* <span className="wave">{wave}</span> */}
          </div>
          <div className="copy-area">
            <div className="inner-column">
              <div className="message">
                <blockquote>{message.text}</blockquote>
                {message.author ? (
                  <cite>
                    <span>{message.author}</span>
                  </cite>
                ) : null}
              </div>
              <Button
                label={__('Continue', 'continue')}
                presentation="teal"
                size="large"
                fullWidth
                onClick={onDismiss}
              />
            </div>
          </div>
        </Wrapper>
      )}
    </AnimatePresence>
  );
});
