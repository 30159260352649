import { styled } from '@naan/stitches.config';

export const VideoModalCard = styled('div', {
  position: 'absolute',
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
  overflow: 'hidden',
  '@medium': {
    borderRadius: '$space$3',
  },

  touchEvents: 'none',
  backgroundSize: 'cover',
  opacity: 0,
  transition: 'opacity 0.3s ease-in-out',
  variants: {
    onTop: {
      true: {
        zIndex: 3,
        touchEvents: 'auto',
        opacity: 1,
      },
    },
  },
});
