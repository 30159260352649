import * as React from 'react';
import { Link } from 'react-router-dom';
import { styled } from '@naan/stitches.config';
import { CountdownClock } from './countdown-clock';
import { subscribePath } from './nav/path-helpers';
import { AppFactory } from '@app/app-factory';

import __ from '@core/lib/localization';

const Wrapper = styled(Link, {
  all: 'unset',
  cursor: 'pointer',
  textDecoration: 'none',
  display: 'block',
  backgroundColor: '$colors$green-600',
  '& .inner': {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBlock: 12,
    paddingInline: 16,

    '& .text': {
      color: 'white',
      textStyle: 'body-bold',
    },
  },

  '@medium': {
    '& .inner': {
      width: 'auto',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 12,
    },
  },
});

export const CountdownNotice = () => {
  const { timedDiscountActive, discountCountdownSeconds } =
    AppFactory.root.userManager.membership;

  if (!timedDiscountActive) {
    return null;
  }

  return (
    <Wrapper to={subscribePath()}>
      <div className="inner">
        <div className="text">
          {__('Subscribe now to save 20%', 'subscribeNowToSave')}
        </div>
        <div className="clock">
          <CountdownClock
            seconds={discountCountdownSeconds}
            showLabel={false}
          />
        </div>
      </div>
    </Wrapper>
  );
};
