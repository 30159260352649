import React from 'react';
import { observer } from 'mobx-react';
import { AppFactory } from '@app/app-factory';
import { CurrentDateSpinner } from 'components/admin/current-date-dev-ui';
import { HStack, VStack } from '@naan/primitives/stack';
import { Menu, MenuItem } from '@naan/primitives/menus';
import { Box } from '@naan/primitives/box';
import { Divider } from '@naan/primitives/divider';
import { RetryIcon } from '@naan/icons/retry-icon';
import { GearIcon } from '@naan/icons/gear-icon';
import { Text } from '@naan/primitives/text';
// import { useNavigate } from 'react-router-dom';
//@ts-expect-error
import { version } from '@jw-spa-version';
import { CheckmarkCircleSmallIcon } from '@naan/icons/checkmark-circle-icon';
import { CloseSmallIcon } from '@naan/icons/close-icon';
import { HSpacer } from '@naan/primitives/spacer';
import { Insets } from 'native-support/insets';
import { styled } from '@naan/stitches.config';
import { reloadOrNativeReset } from '@core/lib/app-util';
// import { devToolsPath } from './nav/path-helpers';
import { presentSettingsModal } from './settings';
import { presentDevToolsModal } from 'routes/dev-tools';
import { selectNextAvailableLocale } from 'routes/dev-tools/dev-toggles';

const Wrapper = styled('div', {
  position: 'fixed',
  // right: 0,
  // top: 0 + Insets.top,

  left: 0,
  top: 'calc( 30% - 18px )',

  zIndex: 10000,
  '@medium': {
    left: 'auto',
    top: 'auto',
    right: 0,
    bottom: 0,
  },
});

const TriggerWrapper = styled('div', {
  cursor: 'pointer',
  background: '$colors$black-alpha-30',
  color: '$colors$white',
  width: 32,
  height: 32,
  borderRadius: '0 32px 32px 0',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& > span': {
    position: 'relative',
    left: -2,
    bottom: 1,
    // left: 4,
    // bottom: 4,
  },
  '@medium': {
    width: 36,
    height: 36,
    background: '$colors$black-alpha-40',
    borderRadius: '32px 0 0 0',
    '& > span': {
      position: 'relative',
      left: 4,
      top: 4,
      fontSize: 16,
    },
  },
});

const PiMenuEnabled = observer(() => {
  const { root, wakeLock } = AppFactory;
  const { userDataSync, firebaseConnection } = AppFactory;
  const listening = userDataSync?.isListening;

  React.useEffect(() => {
    const keyboardBindings = (e: KeyboardEvent) => {
      if (e.key === '`' && e.ctrlKey) {
        presentDevToolsModal();
      }
      if (e.key === ';' && e.ctrlKey) {
        presentSettingsModal();
      }
      if (e.key === '\\' && e.ctrlKey) {
        selectNextAvailableLocale();
      }
    };

    window.addEventListener('keydown', keyboardBindings);
    return () => {
      window.removeEventListener('keydown', keyboardBindings);
    };
  });

  return (
    <Wrapper>
      <Menu
        trigger={
          <TriggerWrapper>
            <span>π</span>
          </TriggerWrapper>
        }
      >
        <MenuItem
          action={() => {
            setTimeout(() => presentDevToolsModal(), 100);
          }}
          label="Dev tools"
          leftIcon={<GearIcon />}
          rightIcon={<Text color="black-alpha-30">...</Text>}
        />
        <MenuItem
          action={() => {
            presentSettingsModal();
          }}
          label="Settings"
          leftIcon={<GearIcon />}
        />
        <VStack>
          <Box css={{ padding: 12 }}>
            <CurrentDateSpinner />
          </Box>
          <Divider />
          <HStack css={{ padding: 12 }} align="center">
            {listening ? (
              <CheckmarkCircleSmallIcon color="var(--colors-green-500)" />
            ) : (
              <CloseSmallIcon color="var(--colors-red-500)" />
            )}
            <HSpacer size={1} />
            Firestore {listening ? '' : 'not'} listening
            <br />
            {firebaseConnection.status}
          </HStack>
          <Divider />
          <HStack css={{ padding: 12 }} align="center">
            {wakeLock.active ? (
              <CheckmarkCircleSmallIcon color="var(--colors-green-500)" />
            ) : (
              <CloseSmallIcon color="var(--colors-red-500)" />
            )}
            <HSpacer size={1} />
            Wakelock: {wakeLock.type}
          </HStack>
          <Divider />
          <Box css={{ padding: 12 }}>
            {root.offline ? 'Offline' : 'Online'}
            <br />
            Version: {version}
            <br />
            Insets: {Insets.top} / {Insets.bottom}
            <br />
            Update prompt enabled: {String(root.updatePromptEnabled)}
            {/* {root.debugStatus ? (
              <>
                <br />
                {root.debugStatus}
              </>
            ) : null} */}
          </Box>
          <Divider />
        </VStack>
        <MenuItem
          action={() => reloadOrNativeReset()}
          label="Reload"
          leftIcon={<RetryIcon />}
        />
      </Menu>
    </Wrapper>
  );
});

export const PiMenu = observer(() => {
  // const navigate = useNavigate();

  const { root } = AppFactory;
  const { hasAdminAccess } = root.userManager;

  if (root.forceHidePiMenu === true) {
    return null;
  }

  if (!hasAdminAccess) {
    return null;
  }

  return <PiMenuEnabled />;
});

export const DebugStatusBar = observer(() => {
  const { root } = AppFactory;
  if (!root?.userManager?.hasAdminAccess) return null;
  if (!root.localState.debugStatusBarEnabled) return null;

  const parts = [];
  if (!!root.debugStatus) {
    parts.push(root.debugStatus);
  }
  if (root.offline) {
    parts.push('[offline]');
  }
  const message = parts.join(' ');
  if (!message) return null;

  return (
    <Box
      css={{
        position: 'fixed',
        zIndex: 10000,
        left: 12,
        top: 4,
        opacity: 0.5,
      }}
    >
      {message}
    </Box>
  );
});
