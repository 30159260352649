import React from 'react';
import { useTitle } from 'common/hooks/use-title';
import { styled } from 'naan/stitches.config';
import { observer } from 'mobx-react';
import {
  ContentGrid,
  ContentGridInnerContainer,
} from 'components/global-layout';
import { useNavigate, useParams } from 'react-router-dom';
import { AppFactory } from 'app/app-factory';
import { NotFoundScreen } from './not-found-screen';
import { VSpacer } from 'naan/primitives/spacer';
import { CurrentAssignmentContext } from 'components/classrooms/assignment/current-assignment-context';
import { AssignmentDetail } from 'components/classrooms/assignment/assignment-detail';

import { useRadio } from 'common/hooks/use-radio';
import { AssignmentStudentSection } from 'components/classrooms/assignment/assignment-students-section';
import { Divider } from 'naan/primitives/divider';
import { Resources } from 'components/discover/volume';
import { HeadlessVolumePage } from 'components/volume-layouts';
import { FullScreenLoader } from 'components/ds/modals';
import {
  alertSevereError,
  notifySuccess,
} from 'lib/services/notification-service';
import { Assignment } from 'core/models/user-manager';
import { ParentScreenLink } from 'components/ui/parent-screen-link';
import { HeaderContainer } from 'components/header/header-container';
import { classroomNavPath } from 'app/app-helpers';
import { CenterColumnLayout } from 'components/layouts/center-column-layout';
import { DatesVoicesCredits } from 'components/story-detail/story-voices-credits';
import { ClassroomFeedbackButton } from 'components/ui/feedback/classroom-feedback-btn';

import __ from 'core/lib/localization';

const LayoutWrapper = styled('section', {
  gridColumn: '1/-1',
});

const Tabs = styled('div', {
  display: 'flex',
});

const TabItem = styled('button', {
  all: 'unset',
  textStyle: 'body-bold',
  backgroundColor: '$transparent',
  color: '$textSecondary',
  px: '$space$4',
  py: '$space$2',
  borderRadius: '$space$2',
  '&:hover': {
    color: '$teal-500',
  },
  variants: {
    selected: {
      true: {
        color: '$teal-600',
        backgroundColor: '$teal-50',
      },
    },
  },
});

export const ClassroomAssignmentScreen = observer(() => {
  const navigate = useNavigate();
  const { id, slug } = useParams<{ id: string; slug: string }>();
  const { userManager } = AppFactory.root;
  const { accountData } = userManager;
  const classroom = accountData.classroom(id);

  const assignment: Assignment = classroom?.assignments?.find(
    (assignment: Assignment) => assignment.episodeSlug === slug
  );

  const [deleting, setDeleting] = React.useState(false);

  const handleAssignmentRemoval = React.useCallback(
    (slug: string) => {
      setDeleting(true);

      classroom
        .removeAssignmentWithSlug(slug)
        .then(() => {
          navigate(classroomNavPath(classroom), {
            replace: true,
          });
          notifySuccess(__('Assignment deleted', 'assignmentDeleted'));
        })
        .catch(error =>
          alertSevereError({ error, note: 'classroom-assignment - remove' })
        );
    },
    [classroom, navigate]
  );

  useTitle(
    assignment
      ? __('Classroom Assignment: %{title}', 'assignmentTitle', {
          title: assignment.story.title,
        })
      : ''
  );

  const tabLabels = ['students', 'resources', 'story'] as const;
  const tabs = useRadio<typeof tabLabels[number]>('students');

  if (deleting) {
    return <FullScreenLoader />;
  }

  if (!assignment) {
    return <NotFoundScreen />;
  }

  // const { l2 } = useParams<{ l2: string }>();

  return (
    <CurrentAssignmentContext.Provider
      value={{
        classroom,
        assignment,
        deleteAssignment: handleAssignmentRemoval,
      }}
    >
      <CenterColumnLayout css={{ height: '100%' }}>
        <ContentGridInnerContainer>
          <ContentGrid>
            <LayoutWrapper>
              <HeaderContainer>
                <ParentScreenLink
                  to={classroomNavPath(classroom)}
                  title={classroom.label}
                />
              </HeaderContainer>
              <VSpacer size={8} />
              <AssignmentDetail />
              <VSpacer size={20} />
              <Tabs>
                <TabItem
                  onClick={tabs.setter('students')}
                  selected={tabs.isCurrent('students')}
                >
                  {__('Students', 'students')}
                </TabItem>
                <TabItem
                  onClick={tabs.setter('resources')}
                  selected={tabs.isCurrent('resources')}
                >
                  {__('Resources', 'resources')}
                </TabItem>
                <TabItem
                  onClick={tabs.setter('story')}
                  selected={tabs.isCurrent('story')}
                >
                  {__('Story', 'story')}
                </TabItem>
              </Tabs>
              <VSpacer size={4} />
              <Divider />
              <VSpacer size={4} />
              <AssignmentStudentSection show={tabs.isCurrent('students')} />
              {tabs.isCurrent('resources') ? (
                <Resources story={assignment.story} />
              ) : null}
              {tabs.isCurrent('story') ? (
                <>
                  <HeadlessVolumePage story={assignment.story} />
                  <DatesVoicesCredits story={assignment.story} />
                </>
              ) : null}
              <VSpacer size={8} />
            </LayoutWrapper>
          </ContentGrid>
        </ContentGridInnerContainer>
        <ClassroomFeedbackButton />
      </CenterColumnLayout>
    </CurrentAssignmentContext.Provider>
  );
});

export default ClassroomAssignmentScreen;
