import React from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { styled } from '@naan/stitches.config';
import { createAccountPath, signInPath } from 'components/nav/path-helpers';

import __ from '@core/lib/localization';

const Wrapper = styled('div', {
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  gap: '16px',
  marginLeft: 'auto',
  '& a': {
    textDecoration: 'none',
  },
  '& .sign-in-link': {
    textStyle: 'small-text-bold',
    color: '$textSecondary',
    '&:hover': {
      color: '$gray-500',
    },
  },
  '& .create-account-link': {
    textStyle: 'small-text-bold',
    color: '$white',
    backgroundColor: '$blue-500',
    padding: '6px 12px',
    borderRadius: '16px',
    '&:hover': {
      backgroundColor: '$blue-700',
    },
  },
});

export const DashboardAuthButtons = observer(() => {
  return (
    <Wrapper>
      <Link to={signInPath()} className="sign-in-link">
        {__('Sign in', 'signIn')}
      </Link>
      <Link to={createAccountPath()} className="create-account-link">
        {__('Create account', 'createAccount')}
      </Link>
    </Wrapper>
  );
});
