import * as React from 'react';
import { observer } from 'mobx-react';
import { Button } from 'naan/primitives/button';
import { HStack } from 'naan/primitives/stack';
import { styled } from 'naan/stitches.config';
// import { FormError } from 'naan/primitives/forms/form-error';
import { AppFactory } from 'app/app-factory';
// import { useForm } from 'react-hook-form';
// import { yupResolver } from '@hookform/resolvers/yup';

import { useComboForm } from '@common/hooks/use-comboform';
import { CatchAllFormErrors } from '@naan/primitives/forms/catch-all-form-error';
import { schoolNameSchema } from '@core/lib/validation-rules';

import __ from 'core/lib/localization';

// import { notifySuccess } from 'lib/services/notification-service';

/// it's easier to just create a new input component that to try to reuse the existing one
const Input = styled('div', {
  display: 'flex',
  flex: 1,
  borderRadius: '8px',
  background: '$gray-50',
  alignItems: 'center',
  px: '$2',
  marginRight: '$3',
  input: {
    all: 'unset',
    padding: '$2',
    flex: 1,
  },
});

type FormInput = {
  schoolName: string;
};

export const SchoolnameForm = observer(() => {
  const store = AppFactory.root;

  const defaultValues = {
    schoolName: store.userManager.accountData.schoolName ?? '',
  };

  // const { handleSubmit, register, formState } = useForm<FormValues>({
  //   resolver: yupResolver(ValidationSchema) as any,
  //   defaultValues,
  // });

  const {
    register,
    handleSubmit,
    errors,
    clearValidationErrors,
    handleValidationErrors,
  } = useComboForm<FormInput>({
    validationSchema: schoolNameSchema(),
    defaultValues,
  });

  /*
  const handleLogin = React.useCallback(
    ({ email, password }: FormInput) => {
      clearValidationErrors();
      userManager.login(email, password).then(() => {
        /// this could be a toast!
      }, handleValidationErrors);
    },
    [handleValidationErrors, userManager, clearValidationErrors]
  );
*/

  const submitHandler = handleSubmit(({ schoolName }) => {
    store.userManager.updateSchoolName(schoolName).then(() => {
      // notifySuccess(__('School name updated', 'schoolNameUpdated'));
      clearValidationErrors();
    }, handleValidationErrors);
  });

  return (
    <form className="form" onSubmit={submitHandler}>
      <HStack css={{ width: '100%' }}>
        <Input>
          <input
            {...register('schoolName')}
            id="school-name"
            placeholder={__('School name(s)', 'schoolNameS')}
          />
        </Input>
        <Button
          presentation="teal"
          size={'large'}
          label={__('Activate', 'activate')}
          css={{
            $$buttonBackgroundColor: '$colors$yellow-300',
            $$buttonTextColor: '$colors$yellow-900',
          }}
        />
      </HStack>
      <CatchAllFormErrors errors={errors} data-test-id="error-message" />
    </form>
  );
});
