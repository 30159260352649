import { styled } from '@naan/stitches.config';

export const VideoMetaDescription = styled('div', {
  textStyle: 'body-bold',
  background: '$black-alpha-60',
  backdropFilter: 'blur(6px)',
  borderRadius: 8,
  color: '$white',
  padding: '8px 12px',
  zIndex: 2,
});
