import * as React from 'react';

import classNames from 'classnames';
import { styled } from '@naan/stitches.config';
import { StudyModel } from 'study/models/study-model';
import { SoundbiteModel } from 'soundbite/models/soundbite-model';
import {
  isSoundbiteModel,
  isStudyModel,
} from 'player/models/player-model-handle';
import { SettingsUIWrapper } from 'components/settings/general-settings-tab';
import { observer } from 'mobx-react';
import {
  ReplayActionItem,
  SlowReplayActionItem,
  CopyActionItem,
  TranslationActionItem,
  NotationActionItem,
  StudyFromHereActionItem,
  FeedbackActionItem,
} from './sentence-modal-items';
import { MenuContext } from './sentence-menu-context';
import { CompactToolbarSwitchRow } from 'components/settings/player-settings-tab';

type StudyOrSoundbiteModel = StudyModel | SoundbiteModel;

const MenuWrapper = styled('ul', {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  padding: '0 16px var(--sab)',
  $$borderColor: '$colors$gray-100',
  $$buttonTextColor: '$colors$textPrimary',
  $$buttonActiveBackgroundColor: '$colors$gray-20',

  '&.dark': {
    $$borderColor: '$colors$gray-700',
    $$buttonTextColor: '$colors$white',
    $$buttonActiveBackgroundColor: '$colors$gray-700',
  },

  'li.settings': {
    all: 'unset',
    padding: '0 0 16px 0',
    borderTop: '1px solid $$borderColor',
  },
});

const MenuSettingsUiWrapper = styled(SettingsUIWrapper, {
  variants: {
    dark: {
      true: {
        $$titleColor: '$colors$white',
        $$hintColor: '$colors$white-alpha-80',
      },
    },
  },
});

type PlayerType = 'soundbite' | 'study' | 'listen';

const getModelPlayerType = (model: StudyOrSoundbiteModel): PlayerType => {
  if (isSoundbiteModel(model)) {
    return 'soundbite';
  } else if (isStudyModel(model) && model.studyMode) {
    return 'study';
  } else {
    return 'listen';
  }
};

const SoundbiteModalContent = () => {
  return (
    <>
      <ReplayActionItem />
      <SlowReplayActionItem />
      <CopyActionItem />
    </>
  );
};

const StudyModalContent = () => {
  return (
    <>
      <TranslationActionItem />
      <NotationActionItem />
      <SlowReplayActionItem />
      <ReplayActionItem />
      <CopyActionItem />
      <FeedbackActionItem />
    </>
  );
};

const ListenModalContent = () => {
  return (
    <>
      <StudyFromHereActionItem />
      <ReplayActionItem />
      <SlowReplayActionItem />
    </>
  );
};

export const SentenceModalContent = observer(() => {
  const { model } = React.useContext(MenuContext)!;
  const playerType = getModelPlayerType(model);

  return (
    <>
      <MenuWrapper className={classNames({ dark: model?.fluentListenMode })}>
        {playerType === 'soundbite' ? <SoundbiteModalContent /> : null}
        {playerType === 'study' ? <StudyModalContent /> : null}
        {playerType === 'listen' ? <ListenModalContent /> : null}
        <li className={'settings'}>
          <MenuSettingsUiWrapper dark={model.fluentListenMode}>
            <CompactToolbarSwitchRow />
          </MenuSettingsUiWrapper>
        </li>
      </MenuWrapper>
    </>
  );
});
