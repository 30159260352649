import * as React from 'react';
import { observer } from 'mobx-react';
import { Story } from '@core/models/story-manager/story';
import { elementIds } from 'components/dom-utils/element-ids';
import { styled } from '@naan/stitches.config';
import { useSwitch2 } from '@naan/hooks/use-switch-2';
import { VocabListModal } from 'components/story-detail/vocab-list';
import { BookmarkIcon } from '@naan/icons/bookmark-icon';

type VocabListButtonProps = {
  textColor?: string;
  iconColor?: string;
  vocabCount: number;
  responsive?: boolean;
  vocabReviewAction?: () => void;
  vocabManageAction?: () => void;
};

export const VocabListStoryButton = observer(
  ({
    story,
    ...props
  }: { story: Story } & Omit<VocabListButtonProps, 'vocabCount'>) => {
    return <VocabListButton vocabCount={story.vocabCount} {...props} />;
  }
);

const ButtonWrapper = styled('button', {
  all: 'unset',
  color: '$white',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 1,
  padding: 8,
});

const VocabListButton = ({ vocabCount }: VocabListButtonProps) => {
  const ref = React.useRef<HTMLButtonElement>(null);
  const prevCount = React.useRef(vocabCount);
  const vocabSwitch = useSwitch2(false);

  React.useEffect(() => {
    if (ref.current) {
      if (vocabCount > prevCount.current) {
        ref.current.classList.add('animate');
        ref.current.addEventListener('animationend', () => {
          ref.current?.classList.remove('animate');
        });
      }
    }
    prevCount.current = vocabCount;
  }, [vocabCount]);

  return (
    <>
      <ButtonWrapper onClick={vocabSwitch.toggle} id={elementIds.VOCAB_BUTTON}>
        <BookmarkIcon />
        {vocabCount}
      </ButtonWrapper>
      {vocabSwitch.value ? (
        <VocabListModal
          onDismiss={() => {
            vocabSwitch.off();
          }}
        />
      ) : null}
    </>
  );
};
