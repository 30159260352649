import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { observer } from 'mobx-react';
import { namedStyled, styled } from '@naan/stitches.config';
import { MarkSimple } from '@naan/primitives/text';
import { Image } from '@naan/primitives/image';
import { SoundbiteEdition } from '@core/models/story-manager/soundbite-edition';
import { ViewStoryButton } from './calendar-view-story-button';
import { SoundbitesStoryBadge } from 'components/stories/soundbite-story-badge';

const Title = styled('h2', {
  textStyle: 'small-heading',
  color: '$colors$textPrimary',
  marginBottom: '$1',
  marginRight: 16,
  '&:hover': {
    color: '$colors$teal-500',
  },
  '@medium': {
    marginRight: 92,
  },
});

const Link = styled(RouterLink, {
  color: 'inherit',
  fontSize: 'inherit',
  fontFamily: 'inherit',
  textDecoration: 'none',
});

const Wrapper = namedStyled('storyCardWrapper', 'div', {
  $$thumbSize: '72px',
  display: 'grid',
  paddingTop: 16,
  position: 'relative',
  marginBottom: 20,

  '@medium': {
    $$thumbSize: '92px',
    gridTemplateColumns: '$$thumbSize 1fr',
    gap: 16,
    position: 'static',
  },

  '& > .thumbnail': {
    position: 'absolute',
    left: 0,
    top: 16,
    img: {
      width: '$$thumbSize',
      aspectRatio: 1,
      objectFit: 'cover',
      borderRadius: 8,
      marginBottom: 16,
    },
    '@medium': {
      position: 'static',
    },
  },

  '&  .header': {
    marginLeft: '$$thumbSize',
    minHeight: '$$thumbSize',
    paddingLeft: 16,
    marginBottom: 16,
    '@medium': {
      marginLeft: 0,
      marginBottom: 0,
      paddingLeft: 0,
      minHeight: 'auto',
    },
  },

  '& > .content': {
    minWidth: 0, // stops assignment buttons pushing .content wide
    '& > .header': {
      position: 'relative',
      paddingRight: 24,
      '& > .button': {
        position: 'absolute',
        right: 0,
        top: 0,
      },
    },
    '&  .edition-meta': {
      display: 'flex',
      gap: 4,
      marginBottom: '$1',
      lineHeight: '20px',
      textStyle: 'small-text',
      color: '$textSecondary',
    },

    '& > .body': {
      textStyle: 'small-text',
      maxWidth: 640,
    },
  },
});

export const CalendarStoryCard = observer(
  ({ edition }: { edition: SoundbiteEdition }) => {
    const story = edition.story;

    if (!story) return null;

    const permalink = `../stories/${story.slug}/soundbites`;
    const { title, listImageUrl } = story;

    return (
      <>
        <Wrapper>
          <div className="thumbnail">
            <Link to={permalink}>
              <Image
                src={listImageUrl}
                alt={title}
                style={{ backgroundColor: story.themeColor }}
              />
            </Link>
          </div>
          <div className="content">
            <header className="header">
              <Link to={permalink}>
                <Title>
                  <MarkSimple source={story.title} />
                </Title>
              </Link>
              <span className="edition-meta">
                {story.countries.join(', ')}
                <SoundbitesStoryBadge story={story} />
              </span>
              <div className="button">
                <ViewStoryButton permalink={permalink} />
              </div>
            </header>
            <div className="body">
              <MarkSimple source={edition.calendarSummary} />
            </div>
          </div>
        </Wrapper>
      </>
    );
  }
);
