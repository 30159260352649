import * as React from 'react';
import { styled } from 'naan/stitches.config';
import { Story } from 'core/models/story-manager';
import { SoundbitesStoryBadge } from './soundbite-story-badge';
import { ShortsBadge } from './shorts-badge';
import { VocabStoryBadge } from './vocab-story-badge';
import { AppFactory } from '@app/app-factory';

const StoryCardMetaWrapper = styled('div', {
  $$color: '$colors$textSecondary',
  marginBottom: '$1',
  lineHeight: '20px',
  textStyle: 'small-text',
  display: 'flex',
  alignItems: 'center',
  gap: 4,

  '& > .meta-text': {
    textStyle: 'small-text',
    color: '$$color',
  },

  '& .channel-title': {
    textStyle: 'small-text-bold',
  },

  variants: {
    mode: {
      dark: {
        $$color: '$colors$white',
      },
    },
  },
});

type WrapperProps = React.ComponentProps<typeof StoryCardMetaWrapper>;

export const StoryCardMeta = ({
  story,
  mode,
  ...props
}: { story: Story } & WrapperProps) => {
  const uglyUi = AppFactory.root.userManager.showUglyDevUI;
  return (
    <StoryCardMetaWrapper mode={mode} {...props}>
      {story.channel ? (
        <span className="meta-text channel-title">{story.channel.title}</span>
      ) : null}
      <span className="meta-text duration-description">
        {story.simpleDurationDescription}
      </span>
      <VocabStoryBadge story={story} onDark={mode === 'dark'} />

      {story.isShort ? <ShortsBadge onDark={mode === 'dark'} /> : null}

      <SoundbitesStoryBadge story={story} onDark={mode === 'dark'} />
      {uglyUi ? (
        <span className="meta-text">{story.releaseDateNotNull}</span>
      ) : null}
    </StoryCardMetaWrapper>
  );
};
