import { styled } from 'naan/stitches.config';

export const Divider = styled('hr', {
  $$color: '$colors$gray-100',
  margin: 0,
  height: 0,
  border: 'none',
  borderBottom: '1px solid $$color',
  width: '100%',
  variants: {
    type: {
      transparent: {
        $$color: '$colors$black-alpha-10',
      },
    },
  },
});
