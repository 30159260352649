import * as DialogPrimitive from '@radix-ui/react-dialog';
import { keyframes, styled } from 'naan/stitches.config';
// import { isFunction } from 'lodash';

const showKeyframes = keyframes({
  '0%': { opacity: 0 },
  '100%': { opacity: 1 },
});

const css = {
  backgroundColor: '$colors$black-alpha-20',
  position: 'fixed',
  inset: 0,
  '@media (prefers-reduced-motion: no-preference)': {
    animation: `${showKeyframes} 0.25s cubic-bezier(0.16, 1, 0.3, 1)`,
  },
};

export const Overlay = styled(DialogPrimitive.Overlay, css);

export const OverlayDiv = styled('div', css);
