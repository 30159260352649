import * as React from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { HomeIcon } from 'naan/icons/home-icon';
import { AvatarIcon } from 'naan/icons/avatar-icon';
import {
  ExternalItemWrapper,
  LinkNavItem,
  NavArrow,
  NavBottomAnchor,
  NavContainer,
  NavItem,
  NavLogoWrapper,
  PopoverNavHeader,
  PopoverNavTrigger,
} from 'components/ui/navigation/responsive-navigation';
import { MenuContent } from '@naan/primitives/menus/menu-content';
// import { SoundbiteIcon } from '@naan/icons/soundbite-icon';
import { MenuItem } from '@naan/primitives/menus';
import { CouponIcon } from '@naan/icons/coupon-icon';
import { RetryIcon } from '@naan/icons/retry-icon';
import { LifebuoyIcon } from '@naan/icons/lifebuoy-icon';
import { SignInIcon } from '@naan/icons/sign-in-icon';
// import { GiftIcon } from '@naan/icons/gift-icon';
import { SignOutIcon } from '@naan/icons/sign-out-icon';
import {
  ArrowTopRightExtraSmallIcon,
  ArrowTopRightIcon,
} from 'naan/icons/arrow-top-right-icon';
import { Text } from 'naan/primitives/text';
import { useNavigate } from 'react-router-dom';
import { useCouponDialog } from 'components/coupons/use-coupon-dialog';
import { Box } from 'naan/primitives/box';
import {
  createAccountPath,
  // soundbitesPath,
  learnHomePath,
  learnProgressPath,
  learnStoriesPath,
  logoutPath,
  marketingSiteL1,
  profileHomePath,
  signInPath,
  supportPath,
  // supportPath,
  teachHomePath,
} from './path-helpers';
import { Button } from 'naan/primitives/button';
import { Responsive } from '@naan/primitives/responsive';
import {
  useAnonymousCouponDialog,
  // useAnonymousGiftCouponDialog,
} from 'components/coupons/use-anonymous-coupon-dialog';
import { AppFactory } from '@app/app-factory';
import {
  clearSavedScrollPosition,
  intentionallyResetAllScrollStorageKeys,
} from 'lib/scroll-utils/deep-scroll-restoration';
import { JwLogo } from 'components/branding/jw-logo';
import { observer } from 'mobx-react';
import { ListIcon } from '@naan/icons/list-icon';
import { PointsIcon } from '@naan/icons/points-icon';
// import { subBrand } from '@core/lib/app-util';
import { reloadOrNativeReset } from '@core/lib/app-util';
import { SettingsIcon } from '@naan/icons/settings-icon';
import { presentSettingsModal } from 'components/settings';
import { styled } from '@naan/stitches.config';
import { useMediaQuery } from 'react-responsive';
import { ShareIcon } from '@naan/icons/share-icon';
import { NotificationService } from '@app/notification-service';

import __ from 'core/lib/localization';

declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    ReactNativeWebView: any;
    embeddedPlatform: string;
  }
}

export const SharedMenuItems = () => {
  const navigate = useNavigate();

  const navigateToSupport = React.useCallback(() => {
    navigate(supportPath());
  }, [navigate]);

  return (
    <>
      <SettingsMenuItem />
      <PurchaseFlowItems />
      <MenuItem
        label={__('Support and feedback', 'supportAndFeedback')}
        leftIcon={<LifebuoyIcon />}
        presentation="navItem"
        action={navigateToSupport}
      />
      <ShareJiveworldItem />
      <MenuItem
        label={__('Reload', 'reload')}
        leftIcon={<RetryIcon />}
        presentation="navItem"
        action={() => {
          // window.location.reload();
          reloadOrNativeReset(); // attempt webview level reset to dashboard with embed params when embedded
        }}
      />{' '}
    </>
  );
};

const PurchaseFlowItems = () => {
  const { purchaseFlowDisabled, authenticated } = AppFactory.root.userManager;
  const currentPath = window.location.pathname;

  // const navigate = useNavigate();

  // const goto = React.useCallback(
  //   (to: string) => () => navigate(to),
  //   [navigate]
  // );

  const handleApplyCode = useCouponDialog();
  const showInterstitialCouponDialog = useAnonymousCouponDialog({
    redirectTo: currentPath,
  });
  // const showInterstitialGiftCouponDialog = useAnonymousGiftCouponDialog();

  // todo: better factor this with teach-navigation.tsx
  if (purchaseFlowDisabled) {
    return null;
  }

  return (
    <>
      <MenuItem
        label={__('Redeem a code', 'redeemACode')}
        leftIcon={<CouponIcon />}
        presentation="navItem"
        action={authenticated ? handleApplyCode : showInterstitialCouponDialog}
      />
      {/* <MenuItem
        label={__('Gift coupons', 'giftCoupons')}
        leftIcon={<GiftIcon />}
        presentation="navItem"
        action={
          authenticated
            ? goto('/app/profile/gift-coupons')
            : showInterstitialGiftCouponDialog
        }
      /> */}
    </>
  );
};

const SettingsMenuItem = () => {
  return (
    <MenuItem
      label={__('Settings', 'settings')}
      leftIcon={<SettingsIcon />}
      presentation="navItem"
      action={() => presentSettingsModal()}
    />
  );
};

const AuthMenuContent = () => {
  const { accountData, classroomEnabled } = AppFactory.root.userManager;

  const navigate = useNavigate();

  const goto = React.useCallback(
    (to: string) => () => navigate(to),
    [navigate]
  );

  const handleLogout = React.useCallback(() => {
    navigate(logoutPath());
    intentionallyResetAllScrollStorageKeys();
  }, [navigate]);

  const handleGoToClassrooms = React.useCallback(() => {
    const to = teachHomePath();
    clearSavedScrollPosition(to as string);
    navigate(to);
  }, [navigate]);

  return (
    <MenuContent
      sideOffset={-6}
      collisionPadding={8}
      css={{ minWidth: '300px' }}
    >
      <PopoverNavHeader>
        <Text textStyle="small-heading">{accountData.name}</Text>
        <Text color="textSecondary" textStyle="small-text">
          {accountData.email}
        </Text>
      </PopoverNavHeader>
      <MenuItem
        label={__('Manage my account', 'manageMyAccount')}
        leftIcon={<AvatarIcon />}
        presentation="navItem"
        action={goto(profileHomePath())}
      />
      <SharedMenuItems />
      <MenuItem
        label={__('Sign out', 'signOut')}
        leftIcon={<SignOutIcon />}
        presentation="navItem"
        action={handleLogout}
      />
      {classroomEnabled ? (
        <Responsive
          renderDefault={() => (
            <Box css={{ padding: 16 }}>
              <Button
                label={__('Go to Classrooms', 'goToClassrooms')}
                onClick={handleGoToClassrooms}
                rightIcon={<ArrowTopRightIcon width={20} height={20} />}
                presentation={'grayLight'}
                size={'small'}
              />
            </Box>
          )}
          renderLargeAndUp={() => null}
        />
      ) : null}
      <DropdownMenu.Arrow asChild>
        <NavArrow />
      </DropdownMenu.Arrow>
    </MenuContent>
  );
};

const ShareJiveworldItem = () => {
  // navigator.share =
  //   navigator.share || ((...args) => Promise.reject(JSON.stringify(args)));
  const { l2Localized } = AppFactory.root;

  const data = React.useMemo(
    () => ({
      title: __('Share Jiveworld', 'shareJiveworld') + '\n',
      // I am no copywriter
      text:
        '\n' +
        __(
          "I'm learning %{l2} as it's really spoken with the Jiveworld app. They use real %{l2} podcast stories featuring different kinds of voices, helping me with vocabulary, local accents, and many other aspects of the language. Have a look!",
          'imLearningL2',
          {
            l2: l2Localized,
          }
        ) +
        '\n',
      url: marketingSiteL1(),
    }),
    [l2Localized]
  );

  const sharingAvailable = navigator.canShare(data);
  const query = `(min-height: 600px)`;
  const fits = useMediaQuery({
    query,
  });

  const shareJw = React.useCallback(() => {
    // use borwsers native share api
    navigator
      .share(data)
      .then(() => {
        NotificationService.open({
          message: __('Thanks for sharing', 'thanksForSharing'),
          type: 'success',
        });
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.error('Error sharing:', error);
      });
  }, [data]);

  if (!sharingAvailable || !fits) {
    return null;
  }

  return (
    <MenuItem
      label={__('Share Jiveworld', 'shareJiveworld')}
      leftIcon={<ShareIcon />}
      presentation="navItem"
      action={shareJw}
    />
  );
};

const AnonMenuContent = () => {
  const navigate = useNavigate();

  const goto = React.useCallback(
    (to: string) => () => {
      clearSavedScrollPosition(to);
      navigate(to);
    },
    [navigate]
  );

  return (
    <MenuContent
      sideOffset={-6}
      collisionPadding={8}
      css={{ minWidth: '300px' }}
    >
      <MenuItem
        label={__('Create account', 'createAccount')}
        leftIcon={<AvatarIcon />}
        presentation="navItem"
        action={goto(createAccountPath())}
      />
      <MenuItem
        label={__('Sign in', 'signIn')}
        leftIcon={<SignInIcon />}
        presentation="navItem"
        action={goto(signInPath())}
      />

      <SharedMenuItems />
      <DropdownMenu.Arrow asChild>
        <NavArrow />
      </DropdownMenu.Arrow>
    </MenuContent>
  );
};

const LabelWrapper = styled('div', {
  '&.name': {
    display: 'none',
    '@large': { display: 'block' },
  },
  '&.me': {
    display: 'block',
    '@large': { display: 'none' },
  },
});

const NavBottom = () => {
  const { accountData, authenticated } = AppFactory.root.userManager;

  return (
    <NavBottomAnchor>
      <DropdownMenu.Root>
        <PopoverNavTrigger id="auth-popover-trigger">
          <NavItem
            icon={<AvatarIcon />}
            label={
              <>
                <LabelWrapper className="name">
                  {authenticated ? accountData.name : __('Account', 'account')}
                </LabelWrapper>
                <LabelWrapper className="me">
                  {authenticated ? __('Me', 'me') : __('Account', 'account')}
                </LabelWrapper>
              </>
            }
          />
        </PopoverNavTrigger>
        {authenticated ? <AuthMenuContent /> : <AnonMenuContent />}
      </DropdownMenu.Root>
    </NavBottomAnchor>
  );
};

export const LearnNavigation = observer(() => {
  // const { userManager: { classroomEnabled } } = AppFactory.root;
  const { userManager, storyManager } = AppFactory.root;
  const { classroomEnabled } = userManager;
  // const { soundbitesEnabled } = storyManager;
  // const { soundbiteCalendarVisible } = userManager.userData;

  const navigate = useNavigate();
  const handleGoToClassrooms = React.useCallback(() => {
    const to = teachHomePath();
    clearSavedScrollPosition(to as string);
    navigate(to);
  }, [navigate]);

  const l2Switch = userManager.anonymous;

  return (
    <NavContainer>
      <NavLogoWrapper>
        <JwLogo subBrand={storyManager.subBrand()} l2Switch={l2Switch} />
      </NavLogoWrapper>
      {classroomEnabled ? (
        <Responsive
          renderLargeAndUp={() => (
            <ExternalItemWrapper>
              <Button
                label={__('Go to Classrooms', 'goToClassrooms')}
                onClick={handleGoToClassrooms}
                rightIcon={<ArrowTopRightExtraSmallIcon />}
                size={'small'}
                presentation={'grayLight'}
              />
            </ExternalItemWrapper>
          )}
        />
      ) : null}
      <LinkNavItem
        to={learnHomePath()}
        icon={<HomeIcon />}
        label={__('Home', 'home')}
      />
      <LinkNavItem
        to={learnStoriesPath()}
        icon={<ListIcon />}
        label={__('Stories', 'stories')}
      />
      {/* {soundbitesEnabled && soundbiteCalendarVisible ? (
        <LinkNavItem
          to={soundbitesPath()}
          icon={<SoundbiteIcon />}
          label={__('Soundbites', 'soundbites')}
        />
      ) : null} */}
      <LinkNavItem
        to={learnProgressPath()}
        icon={<PointsIcon />}
        label={__('My stats', 'myStats')}
      />
      <NavBottom />
    </NavContainer>
  );
});
