import * as React from 'react';
import { styled } from 'naan/stitches.config';
import { DateIcon } from 'naan/icons/date-icon';

import { CalendarWrapper as Calendar } from './calendar-wrapper';
import { useSwitch } from '@naan/hooks/use-switch';

const focusSelector = '&:focus-visible:not(.disabled)';

const StyledWrapper = styled('button', {
  all: 'unset',
  display: 'flex',
  alignItems: 'center',
  backgroundColor: '$gray-50',
  textStyle: 'body',
  color: '$textPrimary',
  borderRadius: '$space$2',
  py: '$space$3',
  px: '$space$4',
  '& > .icon': {
    color: '$gray-400',
    paddingRight: '$space$3',
    lineHeight: 0,
  },
  '& > .label': {
    color: '$textPrimary',
  },
  '& > .placeholder': {
    color: '$gray-300',
  },
  [focusSelector]: {
    boxShadow: 'inset 0px 0px 0px 3px $colors$teal-200',
    boxSizing: 'border-box',
  },
});

type DatePickerProps = {
  placeholder: string;
  value: string;
  onChange: (...event: any[]) => void;
};

export const DatePicker = ({
  placeholder,
  value,
  onChange,
}: DatePickerProps) => {
  const [show, showSwitch] = useSwitch(false);
  /// the server uses a magic date string that amounts to an empty date
  const valueToDisplay = value ? value : placeholder;
  return (
    <>
      <StyledWrapper onClick={showSwitch.toggle} type="button">
        <span className="icon" aria-hidden>
          <DateIcon />
        </span>
        <span className={value ? 'label' : 'placeholder'}>
          {valueToDisplay}
        </span>
      </StyledWrapper>
      {show && (
        <Calendar
          date={value}
          onChange={date => {
            onChange(date);
            showSwitch.off();
          }}
          onReset={() => {
            onChange(null);
            showSwitch.off();
          }}
          onDismiss={showSwitch.off}
        />
      )}
    </>
  );
};
