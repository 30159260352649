import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { observer } from 'mobx-react';
import { namedStyled, styled } from '@naan/stitches.config';
import { Story } from '@core/models/story-manager';
import { StoryCardMeta } from './story-card-meta';
import { MarkSimple } from '@naan/primitives/text';
import { VolumeTagLinks } from 'components/volume-layouts/volume-tag-links';
import { StoryCardMasalaAdmin } from 'components/admin/masala-admin';
import { Image } from '@naan/primitives/image';
import {
  learnStoriesPath,
  learnStorySlugPath,
} from 'components/nav/path-helpers';
import { ChannelAvatar } from 'components/learn/dashboard/channel-avatar';
import { makeFilterLink } from '@common/filtering/make-filter-link';
// import { SoundbiteFilterValues } from '@core/models/story-manager/story';

const Link = styled(RouterLink, {
  color: 'inherit',
  fontSize: 'inherit',
  fontFamily: 'inherit',
  textDecoration: 'none',
});

const Wrapper = namedStyled('storyCardWrapper', 'div', {
  $$thumbSize: '100%',
  padding: 16,
  paddingBottom: 24,
  borderRadius: 16,
  paddingTop: 16,
  color: '$white',

  '& > .thumbnail': {
    img: {
      width: '$$thumbSize',
      aspectRatio: 1,
      objectFit: 'cover',
      borderRadius: 8,
    },
  },

  '& > .body': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: 16,
    paddingTop: 12,
    '& > .content': {
      '& > .header': {
        '& > .story-title': {
          textStyle: 'small-heading',
          display: 'block',
          marginBottom: 4,
        },
      },
      '& .tagline': {
        textStyle: 'small-text',
        display: 'block',
        marginBottom: 12,
      },
      '& .description': {
        display: 'none',
      },
    },
    '& > .avatar': {
      marginTop: 4,
      '& > img': {
        border: '1px solid $white',
      },
    },
  },

  '@small': {
    display: 'grid',
    gridTemplateColumns: '$$thumbSize 1fr',
    gap: 16,
    padding: 16,
    $$thumbSize: '132px',
    '& > .body': {
      paddingTop: 0,
      '& > .avatar': {
        marginTop: 0,
      },
    },
  },
  '@medium': {
    padding: 24,
    gap: 24,
    $$thumbSize: '220px',
    '& > .body': {
      '& > .content': {
        '& > .header': {
          marginBottom: 16,
        },

        '& .tagline': {
          textStyle: 'body-bold',
          marginBottom: 4,
        },
        '& .description': {
          textStyle: 'body',
          marginBottom: 24,
          display: '-webkit-box',
          WebkitLineClamp: 3,
          WebkitBoxOrient: 'vertical',
          overflow: 'hidden',
        },
      },
    },
  },
});

export const NewStoryCard = observer(({ story }: { story: Story }) => {
  if (!story) {
    return null;
  }

  const storyLink = learnStorySlugPath(story.slug);
  const channelLink = story.channel
    ? makeFilterLink(
        learnStoriesPath(),
        // key as TFilterKey,
        'channel', // query param flavor of the key
        [story.channelTitle],
        'release_date'
      )
    : null;

  const { title, listImageUrl } = story;

  return (
    <>
      <Wrapper style={{ backgroundColor: story.themeColor }}>
        <div className="thumbnail">
          <Link to={storyLink}>
            <Image
              src={listImageUrl}
              alt={title}
              style={{ backgroundColor: story.themeColor }}
            />
          </Link>
        </div>
        <div className="body">
          <div className="content">
            <header className="header">
              <Link to={storyLink} className="story-title">
                <MarkSimple source={story.title} />
              </Link>
              <StoryCardMeta story={story} mode="dark" />
            </header>

            <Link to={storyLink}>
              <p className="tagline">
                <MarkSimple source={story.tagline} />
              </p>
              <p className="description">
                <MarkSimple source={story.description} />
              </p>
            </Link>

            <VolumeTagLinks
              tags={story.allTagsExceptShorts}
              linkPrefix={learnStoriesPath()}
              mode={'dark'}
            />
          </div>
          {story.channel ? (
            <Link className="avatar" to={channelLink}>
              <ChannelAvatar channel={story.channel} size="small" />
            </Link>
          ) : null}
        </div>
      </Wrapper>
      <StoryCardMasalaAdmin story={story} />
    </>
  );
});
