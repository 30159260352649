import { VocabReviewModel } from '@core/models/ui/vocab-review-model';
import { Indexed, SentenceId } from '@tikka/basic-types';
import { ElementNode, Sentence } from '@tikka/client/client-aliases';
import { action, makeObservable, observable } from 'mobx';
import { PlayerType } from 'player/models/base-player-model';
import { StudyModel } from 'study/models/study-model';
import { createLogger } from '@common/log';
import { ClientNotation } from '@tikka/client/client-types';
import { RedactionMode } from 'player/models/redaction-modes';
import { flatten, range } from 'lodash';

import {
  definePlayerKeyboardControls,
  startPlayerKeyboardControls,
  stopPlayerKeyboardControls,
} from 'player/views/player-controls/player-keyboard-controls';

const log = createLogger('chapter-review-model');

const notationStatus = {
  'not-added': 'not-added',
  'just-added': 'just-added',
  pending: 'pending', // (question mark icon) for before choice is made
  'got-it': 'got-it', // learned
  'not-got-it': 'not-got-it', // review-again
  'previously-learned': 'previously-learned', // was already tagged as learned as the start of the review session
} as const;

export type NotationStatus = typeof notationStatus[keyof typeof notationStatus];

const WAIT_TIME = 800;

const wait = (ms = WAIT_TIME) =>
  new Promise(resolve => setTimeout(resolve, ms));

export function statusIs(
  status: NotationStatus,
  ...statuses: NotationStatus[]
) {
  return statuses.includes(status);
}

export class ChapterReviewModel extends StudyModel {
  playerType = PlayerType.CHAPTER_REVIEW; // TODO verify takes
  vocabReviewModel: VocabReviewModel; // review flow model and state which spans chapters
  @observable currentPageIndex = 0;

  @observable showingOverlay = true; // "start of chapter" modal
  @observable showingExtras = false;
  @observable endOfChapter = false;

  @observable currentSentenceStatus: 'initial' | 'working' | 'done' = 'initial';
  @observable currentNotationId?: string;

  @observable showingOtherWords = false;

  @observable slowMode = false;

  constructor() {
    super();
    // this.vocabReviewModel = vocabReviewModel;
    makeObservable(this);
  }

  getSentenceRedactionMode() {
    if (this.currentSentenceStatus === 'initial') {
      if (this.showingOtherWords) {
        return RedactionMode.SHOW_SOME;
      }
      return RedactionMode.SHOW_NONE;
    }
    return RedactionMode.SHOW_ALL;
  }

  getWordRedactionMode(word: ElementNode) {
    if (this.currentSentenceStatus === 'initial') {
      const isWordAVocab = this.isWordAVocab(word);
      if (this.showingOtherWords && !isWordAVocab) {
        return RedactionMode.SHOW_ALL;
      }
      return RedactionMode.SHOW_NONE;
    }
    return RedactionMode.SHOW_ALL;
  }

  get sentenceNodes() {
    const selectedNotations = this.vocabReviewModel.currentItems || [];
    const selectedNotationIds = selectedNotations.map(n => n.id);
    const elementNodes = this.elementNodes.filter((node: ElementNode) => {
      // return node.element.kind === 'SENTENCE' && notationCount > 0;
      if (node.element.kind !== 'SENTENCE') {
        return false;
      }
      const sentenceNotations = this.getNotationsForSentence(node.element.id);
      const sentenceNotationIds = sentenceNotations.map(n => n.id);
      // @jason, can you think of a better way to approach filtering for sentences which have selected notations?
      return sentenceNotationIds.some(id => selectedNotationIds.includes(id));
    });

    // just return the first 5 elements
    // return elementNodes.slice(0, 5);
    return elementNodes; //.slice(0, 5);
  }

  // get studyModel() {
  //   return this.chapterReviewModel;
  // }

  get completedPages() {
    // @elliottjf this is a very naive implementation
    return this.currentPageIndex;
  }

  get completedPercentage() {
    if (this.completedPages === 0) {
      return '0%';
    }
    return (this.completedPages / this.pagesCount) * 100 + '%';
  }

  @action
  showOtherWords() {
    this.showingOtherWords = true;
  }

  @action
  showOverlay() {
    this.showingOverlay = true;
  }

  @action
  hideOverlay() {
    this.showingOverlay = false;
  }

  @action
  startReviewSession() {
    this.hideOverlay();
    definePlayerKeyboardControls(this);
    startPlayerKeyboardControls();
  }

  @action
  stopReviewSession() {
    stopPlayerKeyboardControls();
  }

  // @action
  // showExtras() {
  //   this.showingExtras = true;
  // }

  // @action
  // hideExtras() {
  //   this.showingExtras = false;
  // }

  @action
  nextPage() {
    // this.hideExtras();
    if (this.currentPageIndex < this.pagesCount - 1) {
      this.resetState();
      this.currentPageIndex++;
      // this.waitAndMoveToNextReviewItem();
    } else {
      this.endOfChapter = true;
    }
  }

  @action
  prevPage() {
    if (this.currentPageIndex > 0) {
      this.currentPageIndex--;
    }
  }

  @action
  setCurrentSentenceStatus(status: 'initial' | 'working' | 'done') {
    this.currentSentenceStatus = status;
  }

  @action
  setCurrentNotationId(id: ClientNotation['id'] | undefined) {
    this.currentNotationId = id;
  }

  @action
  waitAndMoveToNextReviewItem() {
    void wait(WAIT_TIME).then(() => {
      // const index = first(this.notations, (notation:ClientNotation)=>{
      //   return notation.id !== this.currentNotationId;

      const notations = this.currentSentenceChosableNotations;

      if (notations.length === 0) {
        this.setCurrentNotationId(undefined);
        return;
      }

      if (this.currentNotationId === undefined || notations.length === 1) {
        this.setCurrentNotationId(notations[0].id);
      } else {
        const currentNotationIndex = notations.findIndex(
          n => n.id === this.currentNotationId
        );

        if (currentNotationIndex < notations.length - 1) {
          this.setCurrentNotationId(notations[currentNotationIndex + 1].id);
        } else {
          this.setCurrentNotationId(notations[0].id);
          // this.currentNotationId = undefined;
        }
      }
      // this.setCurrentNotationId(notation.id);
    });
  }

  @action
  resetState() {
    // this.currentPageIndex = 0;
    this.currentSentenceStatus = 'initial';
    this.currentNotationId = undefined;
    this.showingOtherWords = false;
    this.exitSlowMode();
    // this.endOfChapter = false;
  }

  get isCurrentSentenceRevealed() {
    return this.currentSentenceStatus !== 'initial';
  }

  // @action
  // seekToSentence(sentenceId: SentenceId) {
  //   const sentenceNum = Number(sentenceId.split(':')[1]);
  //   const index = this.sentenceNodes.findIndex(
  //     node => node.element.id === sentenceId
  //   );
  //   if (index >= 0) {
  //     this.currentPageIndex = index;
  //   }
  // }

  get currentSentenceChosableNotations() {
    return this.currentSentenceNotations.filter((notation: ClientNotation) => {
      const notationStatus = this.vocabStatus(notation.id);
      return notationStatus === 'pending';
      // return (
      //   notationStatus !== 'not-added' &&
      //   notationStatus !== 'just-added' &&
      //   notationStatus !== 'previously-learned' &&
      //   notationStatus !== 'got-it' &&
      //   notationStattus !== 'not-got-it'
      // );
    });
  }

  get currentNotation() {
    return this.currentSentenceNotations.find(
      n => n.id === this.currentNotationId
    );
  }

  get currentNotationWordIds() {
    if (!this.currentNotation) {
      return [];
    }
    const { address, endAddress } = this.currentNotation;
    return range(address, endAddress + 1);
  }

  get anyPendingNotationsInCurrentSentence() {
    return this.currentSentenceNotations.some(
      n => this.vocabStatus(n.id) === 'pending'
    );
  }

  get pagesCount() {
    return this.sentenceNodes.length;
  }

  get displayCurrentPage() {
    return this.currentPageIndex + 1;
  }

  get currentSentence() {
    return this.sentenceNodes[this.currentPageIndex];
  }

  sentenceFinishedPlaying(sentence: Sentence) {
    if (!sentence) {
      return false;
    }
    const tracker = this.wordTracker;

    // touch the signal that will drive reactivity
    tracker.anyIsChangedSignal.watch();
    // evaluate if the furthest tracked time is past the end of the last word in the sentence
    const words = this.words;
    const sentenceLastWord = words.values[sentence.endAddress];
    const effectiveSentenceEndTime = sentenceLastWord.endTime;
    if (effectiveSentenceEndTime > tracker.furthestTrackedPosition()) {
      return false;
    } else {
      // if (this.restoreSpeed !== 0) {
      //   this.handleSnailReplayRestore(true);
      // }
      return true;
    }
  }

  get currentSentenceNotations() {
    return this.getNotationsForSentence(this.currentSentence.element.id);
  }

  get currentSentenceNotationIds() {
    return flatten(
      this.currentSentenceNotations.map((n: ClientNotation) => {
        const notationStatus = this.vocabStatus(n.id);
        if (statusIs(notationStatus, 'pending', 'got-it', 'not-got-it')) {
          if (n.address === n.endAddress) {
            return [n.address];
          }
          return range(n.address, n.endAddress + 1);
        }
        return [];
      })
    );
  }

  get currentSentenceElementId() {
    return this.currentSentence?.element?.id as SentenceId;
  }

  get currentSentenceElementAddress() {
    // @jason is this a safe assumption? is there a better way to handle the typing?
    return (this.currentSentence?.element as Indexed)?.address;
  }

  get cssCurrentPageIndex() {
    if (this.endOfChapter) {
      return this.pagesCount;
    }
    return this.currentPageIndex;
  }

  isCurrentSentence(sentence: Sentence) {
    // const sentence = this.currentSentence;
    return sentence.id === this.currentSentenceElementId;
  }

  vocabStatus(slug: string): NotationStatus {
    const status = this.vocabReviewModel.currentChapter.status(slug);
    if (!status) {
      if (this.chapter.story.progress.vocabExists(slug)) {
        return 'pending';
      } else {
        return 'not-added';
      }
    } else {
      return status as NotationStatus;
    }
  }

  isWordAVocab(word: ElementNode) {
    return this.currentSentenceNotationIds.includes(Number(word.element.id));
  }

  isWordHighlighted(word: ElementNode) {
    return this.currentNotationWordIds.includes(Number(word.element.id));
  }

  //
  // persist immediately for now - needs more thought
  //

  markJustAdded(slug: string) {
    log.info('markJustAdded', slug);
    this.vocabReviewModel.currentChapter.setStatus(slug, 'just-added');
    this.chapter.story.progress.addVocab(slug, { persist: true });
  }

  undoJustAdded(slug: string) {
    log.info('undoJustAdded', slug);
    this.vocabReviewModel.currentChapter.setStatus(slug, 'not-added');
    this.chapter.story.progress.removeVocab(slug, { persist: true });
  }

  markLearned(slug: string) {
    log.info('markLearned', slug);
    this.vocabReviewModel.currentChapter.setStatus(slug, 'got-it');
    // this.chapter.story.progress.removeVocab(slug, { persist: true });
    this.chapter.story.progress.markVocabLearned(slug, { persist: true });
  }

  markReviewAgain(slug: string) {
    log.info('markReviewAgain', slug);
    this.vocabReviewModel.currentChapter.setStatus(slug, 'not-got-it');
  }

  debugSeekToEnd() {
    if (this.debugMode) {
      const interval = this.currentSentenceTimeInterval;
      if (!interval) {
        return;
      }
      this.player.seek(interval.end - 200);
    }
  }

  get inSlowMode(): boolean {
    // return this.restoreSpeed > 0;
    // @jason, this check wasn't triggering the mobx observable, so added some redundant simples state here.
    // is there a better solution?
    // return this.player.playbackRate < 1;
    return this.slowMode;
  }

  toggleSlowMode() {
    if (this.inSlowMode) {
      this.exitSlowMode();
    } else {
      this.enterSlowMode();
    }
  }

  enterSlowMode() {
    // const currentPlaybackRate = this.transportState.playbackRate;
    // this.player.adjustPlaybackRate(-2);
    // // this.reviewPlaySentence(sentenceId);
    // this.restoreSpeed = currentPlaybackRate;
    this.player.setPlaybackRate(0.7);
    this.slowMode = true;
  }

  exitSlowMode() {
    // this.player.setPlaybackRate(this.restoreSpeed);
    // this.restoreSpeed = 0;
    this.player.setPlaybackRate(1.0);
    this.slowMode = false;
  }

  // beware, attempting to override 'playPauseAction' here resulted in some
  // very confusing and undesired behaviors.
  handleSpaceAction() {
    // ignore space bar when at end.
    if (this.endOfChapter) {
      return;
    }
    if (this.isPlaying) {
      this.pause();
    } else {
      this.reviewPlaySentence(this.currentSentenceElementId);
    }
  }

  // override `+` key handler
  increaseSpeedAction() {
    this.exitSlowMode();
  }

  // override `-` key handler
  reduceSpeedAction() {
    this.enterSlowMode();
  }

  // override `delete` key handler
  sentenceUnredactAction() {
    this.showOtherWords();
  }

  snailReplayCurrentSentence() {
    // no-op for vocab review
  }
}
