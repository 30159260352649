import { styled } from 'naan/stitches.config';

const hoverSelector = '&:hover:not(.disabled)';
const activeSelector = '&:active:not(.disabled), &[data-state=open]';
const focusSelector = '&:focus-visible:not(.disabled)';

export const ButtonStyled = styled('button', {
  $$currentProgress: '0%',
  $$buttonTextColor: '$colors$white',
  $$buttonBackgroundColor: '$colors$gray-600',
  $$py: '$space$2',
  $$px: '$space$3',
  border: 'none',
  display: 'inline-flex',
  alignSelf: 'flex-start',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  py: '$$py',
  px: '$$px',
  borderRadius: '20px',
  textStyle: 'body-bold',
  cursor: 'pointer',
  transitionProperty: 'background',
  transitionDuration: '0.15s',
  color: '$$buttonTextColor',
  backgroundColor: '$$buttonBackgroundColor',
  whiteSpace: 'nowrap',
  maxWidth: '100%',
  '&:focus': {
    outline: 'none',
  },
  [focusSelector]: {
    boxShadow: '0 0 0 4px $colors$teal-500-alpha-50', // placeholder
  },

  variants: {
    alignment: {
      start: {
        alignSelf: 'flex-start',
      },
      unset: {
        alignSelf: 'unset',
      },
    },

    disabled: {
      true: {
        cursor: 'not-allowed',
      },
    },
    size: {
      // @armando, Sorry for hacking up naan. I wanted something lighter weight for the settings actions bar.
      // Will let you tidy this up.
      micro: {
        textStyle: 'small-text',
        fontSize: 12,
        $$py: '4px',
        $$px: '6px',
        '& svg': {
          width: '12px',
          height: '12px',
        },
      },
      extraSmall: {
        textStyle: 'small-text',
        fontSize: 14,
        $$py: '4px',
        $$px: '8px',
        '& svg': {
          width: '12px',
          height: '12px',
        },
      },
      small: {
        textStyle: 'small-text-bold',
        $$py: '6px',
        $$px: '$space$3',
        '& svg': {
          width: '16px',
          height: '16px',
        },
      },
      medium: {
        '& svg': {
          width: '20px',
          height: '20px',
        },
      },
      large: {
        $$py: '$space$3',
        $$px: '$space$4',
        borderRadius: '8px',
        '& svg': {
          width: '24px',
          height: '24px',
        },
      },
      extraLarge: {
        textStyle: 'small-heading',
        $$py: '$space$4',
        $$px: '$space$5',
        borderRadius: '8px',
        '& svg': {
          width: '28px',
          height: '24px',
        },
      },
    },

    radius: {
      squared: {
        borderRadius: '6px',
      },
    },

    presentation: {
      contextual: {
        $$buttonBackgroundColor: '$$contextualButtonBackgroundColor',
        $$buttonTextColor: '$$contextualButtonTextColor',
        [hoverSelector]: {
          $$buttonBackgroundColor: '$$contextualButtonBackgroundColorHover',
        },
        [activeSelector]: {
          $$buttonBackgroundColor: '$$contextualButtonBackgroundColorActive',
        },
        '&.disabled': {
          $$buttonBackgroundColor: '$$contextualButtonBackgroundColorDisabled',
        },
      },
      gray: {
        $$buttonBackgroundColor: '$colors$gray-600',
        [hoverSelector]: {
          $$buttonBackgroundColor: '$colors$gray-700',
        },
        [activeSelector]: {
          $$buttonBackgroundColor: '$colors$gray-800',
        },
        '&.disabled': {
          $$buttonBackgroundColor: '$colors$gray-200',
        },
      },

      grayLight: {
        $$buttonBackgroundColor: '$colors$black-alpha-06',
        $$buttonTextColor: '$colors$black-alpha-50',
        [hoverSelector]: {
          $$buttonBackgroundColor: '$colors$black-alpha-10',
        },
        [activeSelector]: {
          $$buttonBackgroundColor: '$colors$black-alpha-15',
        },
        '&.disabled': {
          opacity: '0.3',
        },
      },

      grayDark: {
        $$buttonBackgroundColor: '$colors$gray-700',
        $$buttonTextColor: '$colors$white',
        [hoverSelector]: {
          $$buttonBackgroundColor: '$colors$gray-800',
        },
        [activeSelector]: {
          $$buttonBackgroundColor: '$colors$gray-900',
        },
        '&.disabled': {
          $$buttonBackgroundColor: '$colors$gray-200',
        },
      },

      teal: {
        $$buttonBackgroundColor: '$colors$teal-500',
        [hoverSelector]: {
          $$buttonBackgroundColor: '$colors$teal-600',
        },
        [activeSelector]: {
          $$buttonBackgroundColor: '$colors$teal-700',
        },
        '&.disabled': {
          $$buttonBackgroundColor: '$colors$teal-200',
        },
      },

      tealLight: {
        $$buttonBackgroundColor: '$colors$teal-50',
        $$buttonTextColor: '$colors$teal-500',

        [hoverSelector]: {
          $$buttonBackgroundColor: '$colors$teal-100',
        },
        [activeSelector]: {
          $$buttonBackgroundColor: '$colors$teal-200',
        },
        '&.disabled': {
          // $$buttonBackgroundColor: '$colors$teal-50',
          opacity: '0.3',
        },
      },

      tealProgress: {
        background: `linear-gradient(
          90deg, $colors$teal-600 $$currentProgress, $colors$teal-500 $$currentProgress
        )`,
      },

      grayProgress: {
        background: `linear-gradient(
          90deg, $colors$gray-600 $$currentProgress, $colors$gray-500 $$currentProgress
        )`,
      },

      grayDarkProgress: {
        background: `linear-gradient(
          90deg, $colors$gray-800 $$currentProgress, $colors$gray-700 $$currentProgress
        )`,
      },

      green: {
        $$buttonBackgroundColor: '$colors$green-500',
        [hoverSelector]: {
          $$buttonBackgroundColor: '$colors$green-600',
        },
        [activeSelector]: {
          $$buttonBackgroundColor: '$colors$green-700',
        },
        '&.disabled': {
          $$buttonBackgroundColor: '$colors$green-200',
        },
      },

      yellow: {
        $$buttonBackgroundColor: '$colors$yellow-300',
        $$buttonTextColor: '$colors$yellow-900',
        [hoverSelector]: {
          $$buttonBackgroundColor: '$colors$yellow-400',
        },
        [activeSelector]: {
          $$buttonBackgroundColor: '$colors$yellow-500',
        },
        '&.disabled': {
          opacity: '0.2',
        },
      },

      blue: {
        $$buttonBackgroundColor: '$colors$blue-500',
        $$buttonTextColor: '$colors$white',
        [hoverSelector]: {
          $$buttonBackgroundColor: '$colors$blue-600',
        },
        [activeSelector]: {
          $$buttonBackgroundColor: '$colors$blue-700',
        },
        '&.disabled': {
          opacity: '0.2',
        },
      },
      purple: {
        $$buttonBackgroundColor: '$colors$purple-500',
        $$buttonTextColor: '$colors$white',
        [hoverSelector]: {
          $$buttonBackgroundColor: '$colors$purple-600',
        },
        [activeSelector]: {
          $$buttonBackgroundColor: '$colors$purple-700',
        },
        '&.disabled': {
          opacity: '0.2',
        },
      },

      red: {
        $$buttonBackgroundColor: '$colors$red-400',
        $$buttonTextColor: '$colors$white',
        [hoverSelector]: {
          $$buttonBackgroundColor: '$colors$red-500',
        },
        [activeSelector]: {
          $$buttonBackgroundColor: '$colors$red-600',
        },

        '&.disabled': {
          opacity: '0.2',
        },
      },

      redLight: {
        $$buttonBackgroundColor: '$colors$red-50',
        $$buttonTextColor: '$colors$red-500',
        [hoverSelector]: {
          $$buttonBackgroundColor: '$colors$red-100',
        },
        [activeSelector]: {
          $$buttonBackgroundColor: '$colors$red-200',
        },

        '&.disabled': {
          opacity: '0.2',
        },
      },

      white: {
        $$buttonTextColor: '$colors$textPrimary',
        $$buttonBackgroundColor: '$colors$white',
        [hoverSelector]: {
          $$buttonBackgroundColor: '$colors$gray-50',
        },
        [activeSelector]: {
          $$buttonBackgroundColor: '$colors$gray-75',
        },
        '&.disabled': {
          opacity: '0.2',
        },
      },

      tealTransparent: {
        $$buttonTextColor: '$colors$teal-500',
        $$buttonBackgroundColor: '$colors$transparent',
        [hoverSelector]: {
          $$buttonTextColor: '$colors$teal-700',
        },
        [activeSelector]: {
          $$buttonTextColor: '$colors$teal-700',
        },
        '&.disabled': {
          $$buttonTextColor: '$colors$teal-200',
        },
      },

      grayTransparent: {
        $$buttonTextColor: '$colors$black-alpha-50',
        $$buttonBackgroundColor: '$colors$transparent',
        [activeSelector]: {
          $$buttonTextColor: '$colors$black-alpha-60',
        },
        [hoverSelector]: {
          $$buttonTextColor: '$colors$black-alpha-60',
        },
        '&.disabled': {
          opacity: '0.2',
        },
      },

      whiteTransparent: {
        $$buttonTextColor: '$colors$white',
        $$buttonBackgroundColor: '$colors$transparent',
        [activeSelector]: {
          $$buttonTextColor: '$colors$white-alpha-80',
        },
        [hoverSelector]: {
          $$buttonTextColor: '$colors$white-alpha-80',
        },
        '&.disabled': {
          opacity: '0.2',
        },
      },

      whiteDarken: {
        $$buttonTextColor: '$colors$white',
        $$buttonBackgroundColor: '$colors$black-alpha-10',
        [hoverSelector]: {
          $$buttonBackgroundColor: '$colors$black-alpha-15',
        },
        [activeSelector]: {
          $$buttonBackgroundColor: '$colors$black-alpha-20',
        },
        '&.disabled': {
          opacity: '0.2',
        },
      },

      whiteLighten: {
        $$buttonTextColor: '$colors$white',
        $$buttonBackgroundColor: '$colors$white-alpha-10',
        [hoverSelector]: {
          $$buttonBackgroundColor: '$colors$white-alpha-15',
        },
        [activeSelector]: {
          $$buttonBackgroundColor: '$colors$white-alpha-20',
        },
        '&.disabled': {
          opacity: '0.2',
        },
      },

      whiteFloating: {
        $$buttonTextColor: '$colors$gray-600',
        $$buttonBackgroundColor: '$colors$white',
        boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.1)',
        border: '1px solid $colors$black-opacity-50',
        [hoverSelector]: {
          $$buttonBackgroundColor: '$colors$gray-20',
          $$buttonTextColor: '$colors$gray-700',
        },
        [activeSelector]: {
          $$buttonBackgroundColor: '$colors$gray-50',
          $$buttonTextColor: '$colors$gray-600',
        },
        '&.disabled': {
          opacity: '0.2',
        },
      },

      destructive: {
        $$buttonBackgroundColor: '$colors$red-50',
        $$buttonTextColor: '$colors$red-500',
        [hoverSelector]: {
          $$buttonBackgroundColor: '$colors$red-100',
        },
        [activeSelector]: {
          $$buttonBackgroundColor: '$colors$red-200',
        },
        '&.disabled': {
          opacity: '0.2',
        },
      },
      secondary: {
        $$buttonBackgroundColor: '$colors$black-alpha-06',
        $$buttonTextColor: '$colors$black-alpha-50',
        [hoverSelector]: {
          $$buttonBackgroundColor: '$colors$black-alpha-10',
        },
        [activeSelector]: {
          $$buttonBackgroundColor: '$colors$black-alpha-15',
        },
        '&.disabled': {
          opacity: '0.3',
        },
      },
      cancel: {
        $$buttonTextColor: '$colors$textSecondary',
        $$buttonBackgroundColor: '$colors$transparent',
        [activeSelector]: {
          $$buttonTextColor: '$colors$gray-600',
        },
        [hoverSelector]: {
          $$buttonTextColor: '$colors$gray-600',
        },
        '&.disabled': {
          opacity: '0.2',
        },
      },

      'text-only-red': {
        $$buttonTextColor: '$colors$red-500',
        $$buttonBackgroundColor: '$colors$transparent',
        [activeSelector]: {
          $$buttonTextColor: '$colors$red-700',
          $$buttonBackgroundColor: '$colors$transparent',
        },
        '&.disabled': {
          $$buttonTextColor: '$colors$red-200',
        },
      },

      activated: {
        $$buttonTextColor: '$colors$teal-500',
        $$buttonBackgroundColor: '$colors$teal-50',
        [activeSelector]: {
          color: '$colors$teal-700',
          $$buttonBackgroundColor: '$colors$teal-100',
        },
        [hoverSelector]: {
          $$buttonBackgroundColor: '$colors$teal-100',
        },
        '&.disabled': {
          $$buttonTextColor: '$colors$teal-200',
        },
      },
    },
    link: {
      true: {
        textDecoration: 'none',
        cursor: 'pointer',
      },
    },
    flush: {
      left: {
        paddingLeft: 0,
      },
      right: {
        paddingRight: 0,
      },
    },
    fullWidth: {
      true: {
        width: '100%',
      },
    },
    fullWidthInMobile: {
      true: {
        '@smallOnly': {
          width: '100%',
        },
      },
    },
  },
  defaultVariants: {
    size: 'medium',
    presentation: 'default',
  },
});

export const ButtonLabel = styled('span', {
  $$px: '$space$1',
  paddingLeft: '$$px',
  paddingRight: '$$px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  variants: {
    size: {
      micro: {
        $$px: '$space$1',
      },
      extraSmall: {
        $$px: '$space$1',
      },
      small: {
        $$px: '$space$1',
      },
      medium: {
        $$px: '$space$2',
      },
      large: {
        $$px: '$space$1',
      },
      extraLarge: {
        $$px: '$space$1',
      },
    },
  },
});

export const LeftButtonIcon = styled('span', {
  lineHeight: 0,
  margin: '0 2px',
  variants: {
    size: {
      micro: {
        margin: '0 -2px 0 2px',
      },
      extraSmall: {
        margin: '0 2px',
      },
      small: {
        margin: '0 2px',
      },
      medium: {
        margin: '0 0 0 4px',
      },
      large: {
        margin: '0 4px 0 0',
      },
      extraLarge: {
        margin: '0 4px 0 0',
      },
    },
  },
});

export const RightButtonIcon = styled('span', {
  lineHeight: 0,
  margin: '0 2px',
  variants: {
    size: {
      micro: {
        margin: '0 1px',
      },
      extraSmall: {
        margin: '0 2px',
      },
      small: {
        margin: '0 2px',
      },
      medium: {
        margin: '0 4px 0 0',
      },
      large: {
        margin: '0 0 0 4px',
      },
      extraLarge: {
        margin: '0 0 0 4px',
      },
    },
  },
});
