/// TODO: split this file into multiple files
import * as React from 'react';

import { ChevronRightSmallIcon } from '@naan/icons/chevron-right-icon';
import { ClockIcon } from '@naan/icons/clock-icon';
import { styled } from '@naan/stitches.config';
import { Link } from 'react-router-dom';
import { InProgressIcon } from '@naan/icons/in-progress-icon';
import { CheckmarkCircleIcon } from '@naan/icons/checkmark-circle-icon';
import { SoundbiteIcon } from '@naan/icons/soundbite-icon';
import {
  storiesPrimaryFiltered,
  storiesTagFiltered,
} from 'components/nav/path-helpers';
import { PointsProgressChart } from 'components/ui/points-progress-chart';
import { Responsive } from '@naan/primitives/responsive';
import { AppFactory } from '@app/app-factory';
import { SoundbiteFilterValues } from '@core/models/story-manager/story';
import classNames from 'classnames';
import { SectionHeading } from './dashboard-section-heading';

import __ from '@core/lib/localization';

const RowWrapper = styled(Link, {
  all: 'unset',
  cursor: 'pointer',
  display: 'grid',
  gridTemplateColumns: 'auto 1fr auto',
  alignItems: 'center',
  borderTop: '1px solid $colors$gray-100',
  '&.zero': {
    display: 'none',
  },
  '& .icon': {
    width: 32,
  },
  '& .label': {
    flex: 1,
    padding: '16px 0',
  },
  '& .accessory': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 8,
    '& .count': {
      textStyle: 'body-bold',
      color: '$textSecondary',
    },
    '& .chevron': {
      color: '$gray-200',
      marginRight: -4,
    },
  },
  '@medium': {
    flexGrow: 1,
    '&:first-child': {
      borderTop: 'none',
    },
    '&.zero': {
      display: 'grid',
    },
  },
});

const StoryListRow = ({
  icon = null,
  label,
  count,
  to,
}: {
  icon?: React.ReactNode;
  label: string;
  count: number;
  to: string;
}) => {
  return (
    <RowWrapper to={to} className={classNames({ zero: count === 0 })}>
      <span className="icon">{icon}</span>
      <span className="label">{label}</span>
      <span className="accessory">
        <span className="count">{count}</span>
        <span className="chevron">
          <ChevronRightSmallIcon />
        </span>
      </span>
    </RowWrapper>
  );
};

const IconContainer = styled('div', {
  variants: {
    presentation: {
      clock: {
        color: '$colors$orange-500',
      },
      progress: {
        color: '$colors$teal-500',
      },
      completed: {
        color: '$colors$gray-500',
      },
      soundbites: {
        color: '$colors$purple-500',
      },
    },
  },
});

const StoryLinksWrapper = styled('div', {
  borderBottom: '1px solid $colors$gray-100',
  '@medium': {
    padding: '0 24px',
    background: '$gray-25',
    borderRadius: 12,
    border: 'none',
    display: 'flex',
    flexDirection: 'column',
  },
});

const StoryListLinksWidget = () => {
  const { storyManager, userManager } = AppFactory.root;
  const { completedSoundbitesCount } = userManager.userData;

  return (
    <StoryLinksWrapper>
      <StoryListRow
        icon={
          <IconContainer presentation={'clock'}>
            <ClockIcon />
          </IconContainer>
        }
        label={__('Study later', 'studyLater')}
        count={storyManager.queued.length}
        to={storiesPrimaryFiltered('queued')}
      />
      <StoryListRow
        icon={
          <IconContainer presentation={'progress'}>
            <InProgressIcon />
          </IconContainer>
        }
        label={__('In progress', 'inProgress')}
        count={storyManager.inProgress.length}
        to={storiesPrimaryFiltered('inProgress')}
      />
      <StoryListRow
        icon={
          <IconContainer presentation={'completed'}>
            <CheckmarkCircleIcon />
          </IconContainer>
        }
        label={__('Completed stories', 'completedStories')}
        count={storyManager.completed.length}
        to={storiesPrimaryFiltered('completed')}
      />
      {storyManager.completedWithUncompletedSoundbites.length ? (
        <StoryListRow
          label={__(
            '…with unplayed Soundbites',
            'ellipsisWithUnplayedSoundbites'
          )}
          count={storyManager.completedWithUncompletedSoundbites.length}
          to={storiesTagFiltered(
            'sb',
            SoundbiteFilterValues.completedWithUnstartedFn()
          )}
        />
      ) : null}
      <StoryListRow
        icon={
          <IconContainer presentation={'soundbites'}>
            <SoundbiteIcon />
          </IconContainer>
        }
        label={__("Find Soundbites I've done", 'findSoundbitesIveDone')}
        count={completedSoundbitesCount}
        to={storiesTagFiltered('sb', SoundbiteFilterValues.withCompletedFn())}
      />
    </StoryLinksWrapper>
  );
};

const WidgetWrapper = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: 24,
});

export const DashboardWidgets = () => {
  return (
    <Responsive
      renderDefault={() => (
        <>
          <SectionHeading title={__('My Stories', 'myStories')} />
          <StoryListLinksWidget />
        </>
      )}
      renderMediumAndUp={() => (
        <WidgetWrapper>
          <StoryListLinksWidget />
          <PointsProgressChart />
        </WidgetWrapper>
      )}
    />
  );
};
