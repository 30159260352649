// codemod-ignore-file
import * as React from 'react';
import { styled } from '@naan/stitches.config';
import { Text } from '@naan/primitives/text';
import { FeedbackButton } from 'components/ui/feedback/feedback-button';
import { observer } from 'mobx-react';
import { StudyModel } from 'study/models/study-model';

import __ from '@core/lib/localization';

const Wrapper = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderTop: '1px solid $black-alpha-10',
  paddingBlock: 16,
  margin: '0 16px',
  '&.dark': {
    borderColor: '$white-alpha-10',
  },
  // paddingBottom: 56,
});

export const ChapterFeedbackButton = observer(
  ({ model }: { model: StudyModel }) => {
    const { studyMode, chapter } = model;

    return (
      <Wrapper className={studyMode ? '' : 'dark'}>
        <FeedbackButton
          presentation={studyMode ? 'light' : 'dark'}
          label={__(
            'Give feedback on this chapter',
            'giveFeedbackOnThisChapter'
          )}
          dialogContext={
            <>
              <Text textStyle="body-bold">{chapter.story.title}</Text>
              <Text>
                {chapter.position}. {chapter.title}
              </Text>
            </>
          }
          metadata={{
            type: 'chapter',
            story: chapter.story,
            chapter: chapter,
            context: chapter.title,
            uglyContext: `${chapter.partChapterLabel} - ${chapter.title}`,
          }}
        />
      </Wrapper>
    );
  }
);
