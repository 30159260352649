import * as React from 'react';
import { ChapterCatalogData } from '@core/models/catalog';
import { observer } from 'mobx-react';
import { ChapterListHeader } from './chapter-item-contents/chapter-list-header';
import { Button } from '@naan/primitives/button';
import { ArrowRightIcon } from '@naan/icons/arrow-right-icon';
import { useChapterPositionUiContext } from './chapter-item-contents/chapter-position-ui-model';
import { ChapterSoundbites } from './chapter-item-contents/chapter-soundbites';
import { ChevronUpIcon } from '@naan/icons/chevron-icon';
import { keyframes, styled } from '@naan/stitches.config';
import { ChapterSoundbiteBadge } from './components/chapter-soundbite-badge';
import { CircleIcon } from './components/circle-icon';

import __ from '@core/lib/localization';
import { ChapterItemContents } from './chapter-item-contents/chapter-item-contents';

const spinOnceAnimation = keyframes({
  '0%': {
    transform: 'rotateX(90deg)',
  },
  '100%': {
    transform: 'rotateX(0deg)',
  },
});

const Accessory = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: 12,
  '& .chevron': {
    color: '$gray-400',
    // run animation once
    animation: `${spinOnceAnimation} .2s linear`,
  },
});

const ChapterSoundbitesWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
  marginBottom: 16,
});

const ButtonWrapper = styled('div', {
  padding: '0 0 16px 40px',
});

export const FurthestNotCurrent = observer(
  ({ chapter }: { chapter: ChapterCatalogData }) => {
    const positionUiModel = useChapterPositionUiContext();
    const expanded =
      positionUiModel.currentChapterPosition === chapter.position;
    const parent = React.useRef<HTMLDivElement>(null);

    return (
      <>
        <div ref={parent} style={{ overflowY: 'clip' }}>
          <div
            onClick={() => {
              positionUiModel.setChapterPosition(chapter.position);
            }}
          >
            <ChapterListHeader
              chapter={chapter}
              stationIcon={<CircleIcon presentation="darkGray" />}
              accessory={
                <Accessory>
                  {expanded ? (
                    <div className="chevron">
                      <ChevronUpIcon />
                    </div>
                  ) : (
                    <ChapterSoundbiteBadge chapter={chapter} />
                  )}
                </Accessory>
              }
            />
          </div>
          {expanded ? (
            <ChapterItemContents>
              <ChapterSoundbitesWrapper>
                <ChapterSoundbites chapter={chapter} />
              </ChapterSoundbitesWrapper>
            </ChapterItemContents>
          ) : null}
          <ButtonWrapper>
            <Button
              leftIcon={<ArrowRightIcon />}
              label={__('Resume study', 'resumeStudy')}
              onClick={() => chapter.resumeStudy()}
              presentation={'secondary'}
              size={'extraLarge'}
              fullWidth
            />
          </ButtonWrapper>
        </div>
      </>
    );
  }
);
