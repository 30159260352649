import React from 'react';
import { Text } from 'naan/primitives/text';
import { styled } from '@naan/stitches.config';

const Container = styled('div', {
  '& > .content': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  '&.with-rule': {
    paddingBottom: '8px',
    borderBottom: '1px solid $colors$gray-100',
  },

  '&.with-margin': {
    marginBottom: '16px',
  },

  '&.without-margin': {
    '&.with-rule': {
      marginBottom: '-1px',
    },
  },

  '@media screen and (min-width: 550px)': {
    '&.is-sticky': {
      position: 'sticky',
      left: '0',
      top: '68px',
      right: '0',
      background: '$colors$gray-20',
      padding: '8px 0',
      zIndex: '1900',
      borderBottom: '1px solid $colors$gray-100',

      '& > .content': {
        // maxWidth: getDimension('max-screen-width-without-padding'),
        margin: '0 auto',
      },

      '.header-back-link': {
        display: 'none',
      },

      '@media screen and (min-width: 768px)': {
        top: '80px',
      },
    },
  },

  '@media screen and (max-width: 549px)': {
    '&.is-sticky': {
      position: 'sticky',
      left: '0',
      top: '0',
      right: '0',
      background: '$colors$gray-20',
      padding: '8px 0',
      zIndex: '1900',
      borderBottom: '1px solid $colors$gray-100',

      '& > .content': {
        // maxWidth: getDimension('max-screen-width-without-padding'),
        margin: '0 auto',
      },

      '.header-back-link': {
        display: 'none',
      },
    },

    '& > .content': {
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
    },

    '.heading-control': {
      marginTop: '16px',
    },
  },
});

// @deprecated use other components instead
export const Heading = ({
  withRule = true,
  withMargin = true,
  control,
  children,
}: {
  withRule?: boolean;
  withMargin?: boolean;
  control?: React.ReactNode;
  children: React.ReactNode;
  //@deprecated
  makeSticky?: boolean;
}) => {
  return (
    <>
      <Container
        className={`${withRule ? 'with-rule' : 'without-rule'} ${
          withMargin ? 'with-margin' : 'without-margin'
        } `}
      >
        <div className="content">
          <Text textStyle={'medium-heading'}>{children}</Text>
          <div className="heading-control">{control}</div>
        </div>
      </Container>
    </>
  );
};
