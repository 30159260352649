import * as React from 'react';
import { Soundbite } from '@core/models/story-manager/soundbite';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { calendarSoundbitePath } from 'components/nav/path-helpers';
import { styled } from '@naan/stitches.config';
import { SoundbiteEdition } from '@core/models/story-manager/soundbite-edition';
import { icons } from 'components/soundbites/soundbites-icons';
import { CheckmarkHeavySmallIcon } from '@naan/icons/checkmark-heavy-icon';
import { MiniErrorBoundary } from 'components/error-boundary';
import { AppFactory } from '@app/app-factory';

const Wrapper = styled('ul', {
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: 32,
  '@medium': {
    marginBottom: 56,
  },
});

const CardWrapper = styled('li', {
  /// 1/7 - (6 x 4px gutter / 7)
  /// There's probably a better way to do equal widths here!
  width: 'calc(14.2857% - 3.4285px)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '56px',
  background: '$colors$gray-25',
  borderRadius: 8,
  position: 'relative',
  aspectRatio: '1',
  a: {
    padding: '15%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
    textDecoration: 'none',
    border: '2px solid transparent',
    borderRadius: 8,
    '@small': {
      borderWidth: '3px',
    },
    '@medium': {
      borderWidth: '4px',
    },
  },
  variants: {
    current: {
      true: {
        a: {
          borderColor: '$colors$purple-200',
        },
        '&.visited a': {
          borderColor: '$colors$gray-200',
        },
      },
    },
  },
});

const UnvisitedCardWrapper = styled(CardWrapper, {
  '& .icon-large': {
    display: 'none',
    svg: {
      width: '100%',
    },
  },
  '& .icon-small': {
    width: '100%',
    svg: {
      width: '100%',
      height: 'auto',
    },
  },

  '@small': {
    '& .icon-large': {
      display: 'block',
      svg: {
        maxWidth: 72,
      },
    },
    '& .icon-small': {
      display: 'none',
    },
  },
});

// make an Arry of 7 days
// note, only the array length matters, the values are irrelevant
const days = Array.from({ length: 7 }, (_, i) => i + 1);

const UnvisitedCard = observer(
  ({ soundbite, current }: { soundbite: Soundbite; current?: boolean }) => {
    const iconSet = icons[soundbite.category as keyof typeof icons] ?? null;
    const LargeIcon = iconSet?.large ?? null;
    const SmallIcon = iconSet?.small ?? null;

    return (
      <UnvisitedCardWrapper className="unvisited" current={current}>
        <Link to={calendarSoundbitePath(soundbite.slug)}>
          <span className="icon-large">{LargeIcon && <LargeIcon />}</span>
          <span className="icon-small">{SmallIcon && <SmallIcon />}</span>
        </Link>
      </UnvisitedCardWrapper>
    );
  }
);

const RevealedCardWrapper = styled(CardWrapper, {
  background: '$colors$gray-50',
  '& .icon-large': {
    display: 'none',
    svg: {
      width: '100%',
    },
  },
  '& .icon-small': {
    width: '100%',
    svg: {
      width: '100%',
      height: 'auto',
    },
  },
  '& .title': {
    display: 'none',
    textStyle: 'small-text-bold',
    color: '$colors$gray-500',
    textAlign: 'center',
  },
  '& .checkmark': {
    position: 'absolute',
    left: 5,
    top: 5,
    color: '$colors$gray-400',
    '& svg': {
      width: 10,
      height: 10,
    },
  },
  '@small': {
    '& .icon-large': {
      display: 'block',
      svg: {
        maxWidth: 72,
      },
    },
    '& .icon-small': {
      display: 'none',
    },
  },
  '@medium': {
    '& .checkmark': {
      left: 10,
      top: 10,
      '& svg': {
        width: 16,
        height: 16,
      },
    },
  },
  '@extraLarge': {
    '& .title': {
      display: 'block',
    },
    '& .icon-large': {
      display: 'none',
    },
    '& .icon-small': {
      display: 'block',
      width: 32,
      height: 32,
      marginBottom: 4,
    },
  },
});

const RevealedCard = observer(
  ({
    soundbite,
    completed,
    current,
  }: {
    soundbite: Soundbite;
    completed?: boolean;
    current?: boolean;
  }) => {
    const iconSet = icons[soundbite.category as keyof typeof icons] ?? null;
    const SmallIcon = iconSet?.small ?? null;
    const LargeIcon = iconSet?.large ?? null;

    return (
      <RevealedCardWrapper className="visited" current={current}>
        <Link to={calendarSoundbitePath(soundbite.slug)}>
          {completed ? (
            <span className="checkmark">
              <CheckmarkHeavySmallIcon />
            </span>
          ) : null}
          <span className="icon-small">
            {SmallIcon && <SmallIcon presentation="grayscale" />}
          </span>
          <span className="icon-large">
            {LargeIcon && <LargeIcon presentation="grayscale" />}
          </span>
          <span className="title">{soundbite.title}</span>
        </Link>
      </RevealedCardWrapper>
    );
  }
);

const FutureCardWrapper = styled(CardWrapper, {
  border: '1px solid $colors$gray-100',
  background: '$colors$white',
  svg: {
    width: 24,
    height: 24,
    '@small': {
      width: 32,
      height: 32,
    },
    '@medium': {
      width: 40,
      height: 40,
    },
  },
});

const FutureCard = observer(() => {
  return (
    <FutureCardWrapper>
      <svg
        width={41}
        height={40}
        viewBox="0 0 41 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M34.1904 19.9997C34.1904 27.3635 28.2209 33.333 20.8571 33.333C13.4933 33.333 7.52376 27.3635 7.52376 19.9997C7.52376 12.6359 13.4933 6.66634 20.8571 6.66634C28.2209 6.66634 34.1904 12.6359 34.1904 19.9997ZM20.8571 36.6663C30.0618 36.6663 37.5238 29.2044 37.5238 19.9997C37.5238 10.7949 30.0618 3.33301 20.8571 3.33301C11.6524 3.33301 4.19043 10.7949 4.19043 19.9997C4.19043 29.2044 11.6524 36.6663 20.8571 36.6663ZM21.6904 9.99967V19.1663H29.1904V20.833H20.0238V9.99967H21.6904Z"
          fill="#E6E6E6"
        />
      </svg>
    </FutureCardWrapper>
  );
});

const SoundbiteCalendarCard = observer(
  ({ soundbite, current }: { soundbite: Soundbite; current: boolean }) => {
    if (soundbite.completed) {
      return <RevealedCard soundbite={soundbite} completed current={current} />;
    }

    return <UnvisitedCard soundbite={soundbite} current={current} />;
  }
);

export const SoundbiteCalendarEdition = observer(
  ({ edition }: { edition: SoundbiteEdition }) => {
    const { storyManager } = AppFactory.root;
    const currentDoW = storyManager.currentDayOfWeek;
    const { currentWeek } = edition;

    return (
      <>
        <Wrapper>
          {days.map((_, dayIndex) => {
            if (edition.calendarSoundbites[dayIndex]) {
              const soundbite = edition.calendarSoundbites[dayIndex];
              const currentSoundbite = currentWeek && dayIndex === currentDoW;
              const futureSoundbite = currentWeek && dayIndex > currentDoW;
              return (
                <MiniErrorBoundary key={dayIndex}>
                  {futureSoundbite ? (
                    <FutureCard />
                  ) : (
                    <SoundbiteCalendarCard
                      soundbite={soundbite}
                      current={currentSoundbite}
                    />
                  )}
                </MiniErrorBoundary>
              );
            } else {
              return <li key={dayIndex}>---</li>;
            }
          })}
        </Wrapper>
      </>
    );
  }
);
