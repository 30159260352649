import * as React from 'react';
import { styled } from '@naan/stitches.config';

import { cms } from '@core/lib/localization-helpers';
// import { LocaleCode } from '@utils/util-types';
// import { AppFactory } from '@app/app-factory';

import allSoundbitesImgSrc from './assets/all-soundbites.png';
import enAllStoriesDesktopImgSrc from './assets/en-all-stories-desktop.png';
import enAllStoriesMobileImgSrc from './assets/en-all-stories-mobile.png';
import ptAllStoriesDesktopImgSrc from './assets/pt-all-stories-desktop.png';
import ptAllStoriesMobileImgSrc from './assets/pt-all-stories-mobile.png';
import { Responsive } from '@naan/primitives/responsive';
import { Image } from '@naan/primitives/image';
import { pickLocalizedValue } from '@core/lib/localization';

const Wrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 24,
  paddingBottom: 40,
  width: 'min(100%, 464px)',

  '@medium': {
    width: 'min(100vw - 48px, 960px)',
    gap: 40,
    paddingBottom: 96,
  },

  '.row': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 16,

    '& > img': {
      width: 343,
      maxWidth: '100%',
    },

    '& .text': {
      display: 'flex',
      flexDirection: 'column',
      gap: 4,
      color: '$colors$white',
      textStyle: 'body',
      '& > h3': {
        textStyle: 'small-heading',
      },
    },

    '@small': {
      width: 464,
    },

    '@medium': {
      width: 'auto',
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gap: 32,
      '& > img': {
        width: '100%',
      },
    },
  },
});

// const storiesBlurb = (l2: LocaleCode) => {
//   // switch (l2) {
//   //   case 'en':
//   //     return __(
//   //       'These stories capture every facet of life ...',
//   //       'subscribeBenefitsStoriesBlurb.en'
//   //     );
//   //   default:
//   //     return __(
//   //       `These Radio Ambulante stories capture every facet of life in Latin America. You'll listen and understand real Spanish as spoken by 100s of people — young and old, famous and everyday — from over 20 countries.`,
//   //       'theseRadioAmbulanteStoriesCaptureEveryFacetOfLifeInLatinAmerica'
//   //     );
//   // }
//   return translateWithoutDefault(`cms:subscribeBenefitsStoriesBlurb:${l2}`);
// };

export const SubscriptionBenefits = () => {
  // const { l2 } = AppFactory.root;
  const allStoriesDesktopImgSrc = pickLocalizedValue({
    en: enAllStoriesDesktopImgSrc,
    pt: ptAllStoriesDesktopImgSrc,
  });

  const allStoriesMobileImgSrc = pickLocalizedValue({
    en: enAllStoriesMobileImgSrc,
    pt: ptAllStoriesMobileImgSrc,
  });

  return (
    <Wrapper>
      <div className="row">
        <Responsive
          renderDefault={() => <Image src={allStoriesMobileImgSrc} />}
          renderMediumAndUp={() => null}
        />
        <div className="text">
          <h3>{cms('subscribeBenefitsStoriesHeading')}</h3>
          <p>{cms('subscribeBenefitsStoriesBlurb')}</p>
        </div>
        <Responsive
          renderDefault={() => null}
          renderMediumAndUp={() => <Image src={allStoriesDesktopImgSrc} />}
        />
      </div>
      <div className="row">
        <Responsive
          renderDefault={() => null}
          renderMediumAndUp={() => <Image src={allSoundbitesImgSrc} />}
        />
        <div className="text">
          <h3>{cms('allSoundbitesAcross8CategoriesHeading')}</h3>
          <p>{cms('allSoundbitesAcross8CategoriesBlurb')}</p>
        </div>
      </div>
    </Wrapper>
  );
};
