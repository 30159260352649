import * as React from 'react';
import { VSpacer } from 'naan/primitives/spacer';
import { styled } from 'naan/stitches.config';
import { useCurrentClassroom } from '../current-clasroom-context';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { MarkSimple, Text } from 'naan/primitives/text';
import { Assignment } from 'core/models/user-manager';
import { Divider } from 'naan/primitives/divider';
import { DateSmallIcon } from 'naan/icons/date-icon';
import { NoteSmallIcon } from 'naan/icons/note-icon';
import { VStack } from 'naan/primitives/stack';
import { BrowseStoriesButton } from 'components/classrooms/browse-stories-button';
import __ from 'core/lib/localization';
import { Image } from '@naan/primitives/image';
import { VolumeCardMeta } from 'components/volume-layouts/volume-card-meta';

const Row = styled('div', {
  paddingTop: '$space$4',
  paddingBottom: '$space$4',
  borderBottom: '1px solid $colors$gray-100',
  cursor: 'pointer',
  transition: 'background .15s',
  '&:hover': {
    backgroundColor: '$teal-50',
    borderRadius: '12px',
    margin: '-1px -16px 0',
    padding: '17px 16px',
    borderBottom: 'none',
  },
});

const Wrapper = styled('div', {
  $$thumbSize: '64px',
  paddingLeft: 'calc( $$thumbSize + $space$5 )',
  position: 'relative',
  '@medium': {
    display: 'flex',
    alignItems: 'flex-start',
    paddingLeft: 0,
  },
});

const Thumbnail = styled(Image, {
  width: '$$thumbSize',
  height: '$$thumbSize',
  position: 'absolute',
  borderRadius: '$space$1',
  left: 0,
  top: 0,
  '@medium': {
    position: 'static',
    marginRight: '$space$5',
  },
});

const StoryContainer = styled(RouterLink, {
  textDecoration: 'none',
  '@medium': {
    width: '256px',
  },
});
const MetaContainer = styled('div', {
  '& > div': {
    marginTop: '$space$2',
    color: '$textSecondary',
    display: 'flex',
    alignItems: 'flex-start',
    '& > svg': {
      marginRight: '$space$2',
      flexShrink: '0',
    },
  },
  '@medium': {
    flex: 1,
    display: 'flex',
    '& > div': {
      margin: 0,
      minWidth: '256px',
    },
  },
});

const HeadingContainer = styled('div', {
  // display: 'flex',
  marginTop: '$space$8',
  h2: {
    textStyle: 'medium-heading',
    color: '$textPrimary',
    marginBottom: '$space$6',
  },
  '@small': {
    display: 'flex',
    alignItems: 'center',
    h2: {
      marginRight: 'auto',
      marginBottom: '0',
    },
  },
  '@medium': {
    '@large': {
      marginTop: '0',
    },
  },
});

const ZeroState = styled('div', {
  background: '$gray-25',
  padding: '$space$5',
  borderRadius: '$space$3',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  textAlign: 'center',
  '& >.button-container': {
    marginTop: '$space$4',
  },
  '@small': {
    flexDirection: 'row',
    justifyContent: 'space-between',
    textAlign: 'initial',
    '& >.button-container': {
      margin: '0',
    },
  },
});

export const Assignments = () => {
  const { classroom } = useCurrentClassroom();
  const assignments = classroom.assignments;
  const hasAssignments = assignments.length > 0;
  const navigate = useNavigate();

  if (!hasAssignments) {
    return (
      <>
        <HeadingContainer>
          <VSpacer size={5} />
          <h2>{__('Assignments', 'assignments')}</h2>
        </HeadingContainer>
        <VSpacer size={5} />
        <ZeroState>
          <Text>
            {__('No assignments for this class', 'noAssignmentsForThisClass')}
          </Text>
          <div className="button-container">
            <BrowseStoriesButton />
          </div>
        </ZeroState>
      </>
    );
  }

  return (
    <VStack>
      <HeadingContainer>
        <h2>{__('Assignments', 'assignments')}</h2>
        <span className="button">
          <BrowseStoriesButton />
        </span>
      </HeadingContainer>
      <VSpacer size={3} />
      <Divider />
      {assignments.map((assignment: Assignment) => {
        if (!assignment.story) {
          return null;
        }

        const permalink = `assignments/${assignment.episodeSlug}`;

        return (
          <Row
            key={assignment.episodeSlug}
            className="row"
            onClick={() => {
              navigate(permalink);
            }}
          >
            <Wrapper>
              <Thumbnail
                src={assignment.story.listImageUrl}
                alt={assignment.story.title}
              />
              <StoryContainer to={permalink}>
                <Text textStyle={'body-bold'}>
                  <MarkSimple source={assignment.story.title} />
                </Text>
                <VolumeCardMeta story={assignment.story} />
              </StoryContainer>
              <MetaContainer>
                <div>
                  <DateSmallIcon /> {assignment.displayDueDate}
                </div>
                <div>
                  <NoteSmallIcon />
                  {assignment.displayNote}
                </div>
              </MetaContainer>
            </Wrapper>
          </Row>
        );
      })}
    </VStack>
  );
};
