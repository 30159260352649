import * as React from 'react';
import * as Dialog from '@naan/primitives/modals/dialog';

import { Text } from '@naan/primitives/text';
import { StudyModel } from 'study/models/study-model';
import { AppFactory } from '@app/app-factory';
import { Responsive } from '@naan/primitives/responsive';
import { SoundbiteModel } from 'soundbite/models/soundbite-model';
import { SentenceId } from '@tikka/basic-types';
import { MenuContext } from './sentence-menu-context';
import { SentenceModalContent } from './sentence-menu-content';

import __ from '@core/lib/localization';

type StudyOrSoundbiteModel = StudyModel | SoundbiteModel;

const SentenceSheet = ({
  model,
  onDismiss,
}: {
  model: StudyOrSoundbiteModel;
  onDismiss: () => void;
}) => {
  return (
    <Dialog.Container
      open
      onDismiss={onDismiss}
      flavor="sheet"
      css={
        model.fluentListenMode
          ? {
              backgroundColor: '$colors$gray-800',
            }
          : undefined
      }
    >
      <Dialog.Heading>
        <Text textStyle="small-caps" color="textSecondary">
          {__('Sentence actions', 'sentenceActions')}
        </Text>
      </Dialog.Heading>
      <Dialog.CloseButton
        presentation={model.fluentListenMode ? 'whiteTransparent' : undefined}
      />
      <SentenceModalContent />
    </Dialog.Container>
  );
};

const SentenceModal = ({
  model,
  onDismiss,
}: {
  model: StudyOrSoundbiteModel;
  onDismiss: () => void;
}) => {
  return (
    <Dialog.Container
      open
      onDismiss={onDismiss}
      css={
        model.fluentListenMode
          ? {
              backgroundColor: '$colors$gray-800',
            }
          : undefined
      }
    >
      <Dialog.Heading>
        <Text
          textStyle="small-caps"
          color={model.fluentListenMode ? 'white' : 'textSecondary'}
        >
          {__('Sentence actions', 'sentenceActions')}
        </Text>
      </Dialog.Heading>
      <Dialog.CloseButton
        presentation={model.fluentListenMode ? 'whiteTransparent' : undefined}
      />
      <SentenceModalContent />
    </Dialog.Container>
  );
};

export const presentSentenceActions = (
  model: StudyOrSoundbiteModel,
  sentenceId: SentenceId
) => {
  AppFactory.dialogPresenter.present(onDismiss => (
    <Responsive
      renderDefault={() => (
        <MenuContext.Provider value={{ model, onDismiss, sentenceId }}>
          <SentenceSheet onDismiss={onDismiss} model={model} />
        </MenuContext.Provider>
      )}
      renderMediumAndUp={() => (
        <MenuContext.Provider value={{ model, onDismiss, sentenceId }}>
          <SentenceModal onDismiss={onDismiss} model={model} />
        </MenuContext.Provider>
      )}
    />
  ));
};
