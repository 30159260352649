import * as React from 'react';
import { Story } from '@core/models/story-manager';
import { styled } from '@naan/stitches.config';
import { icons } from 'components/soundbites/soundbites-icons';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { chapterStudyIcons } from 'story/chapter-list-item/chapter-item-contents/chapter-study-button';
// import { Responsive } from '@naan/primitives/responsive';

// import {
//   columnGap,
//   thumbnailSize,
// } from 'components/volume-layouts/volume-card-layout';

// const ChapterCardWrapper = styled('div', {
//   display: 'flex',
//   flexDirection: 'row',
//   alignItems: 'center',
//   justifyContent: 'space-between',
//   background: '$gray-25',
//   padding: '12px 16px',
//   borderRadius: 8,
//   marginBottom: 16,
//   marginTop: 16,
//   gap: 20,

//   '@medium': {
//     padding: '16px 16px 16px 24px',
//     borderRadius: 12,
//   },

//   '& > .chapter': {
//     all: 'unset',
//     flex: 1,
//     '& > .part-chapter': {
//       whiteSpace: 'nowrap',
//       textStyle: 'small-text',
//       color: '$textSecondary',
//     },
//     '& > .title-duration': {
//       display: 'flex',
//       cursor: 'pointer',
//       flexDirection: 'row',
//       alignItems: 'center',
//       gap: 8,
//       '& > .title': {
//         textStyle: 'body-bold',
//         display: '-webkit-box',
//         WebkitBoxOrient: 'vertical',
//         WebkitLineClamp: '1',
//         overflow: 'hidden',
//       },
//       '& > .duration': {
//         whiteSpace: 'nowrap',
//         textStyle: 'small-text',
//         color: '$textSecondary',
//         display: 'none',
//         '@medium': {
//           display: 'block',
//           marginTop: 1,
//         },
//       },
//     },
//   },

//   '& > .crumbs': {
//     all: 'unset',
//     cursor: 'pointer',
//     marginLeft: 'auto',
//   },
//   '& > .actions': {
//     display: 'none',
//     '@medium': {
//       display: 'block',
//     },
//   },
// });

const ProgressCardWrapper = styled(Link, {
  all: 'unset',
  $$background: '$colors$white',
  $$iconSize: '24px',
  background: '$$background',
  padding: '12px 16px',
  borderRadius: 12,
  display: 'grid',
  gridTemplateColumns: '1fr auto',
  gap: 8,
  cursor: 'pointer',
  marginTop: 16,
  '@media(min-width: 600px)': {
    width: 368,
  },
  '@medium': {
    padding: '18px 20px',
  },
  '& > .content': {
    overflow: 'hidden',
    '& > .part': {
      color: '$black-alpha-50',
      mixBlendMode: 'multiply',

      whiteSpace: 'nowrap',
      textStyle: 'small-text',
      display: 'block',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    '& > .title': {
      color: '$textPrimary',
      display: 'block',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      textStyle: 'body-bold',
    },
  },

  '& > .soundbite-icon': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 8,
    padding: 8,
    background: 'white',
    '& > svg': {
      width: 24,
      height: 24,
    },
  },
  '& > .study-icon': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '$black-alpha-70',
    '& > svg': {
      width: 32,
      height: 32,
    },
  },

  variants: {
    kind: {
      soundbites: {
        $$background: '$colors$orange-100',
      },
      study: {
        $$background: '$colors$teal-200',
      },
      listen: {
        $$background: '$colors$gray-100',
      },
      completed: {
        $$background: '$colors$green-200',
      },
    },
  },
});

export const StoryChapterCard = observer(
  ({ story }: { story: Story; viaDetail: boolean }) => {
    const permalink = `../stories/${story.slug}`;
    const { progressMayBeNull, progress } = story;
    const chapter = progressMayBeNull?.currentChapter;

    if (progressMayBeNull === null || chapter === null) {
      return null;
    }

    const stage = progress.currentStage;

    if (stage === 'soundbites') {
      const currentPart = chapter.partChapterLabel;
      const currentSoundbite =
        chapter.chapterSoundbites[progress.firstIncompleteSoundbiteIndex];
      const iconSet =
        icons[currentSoundbite.category as keyof typeof icons] ?? null;
      const SmallIcon = iconSet?.small ?? null;

      return (
        <ProgressCardWrapper kind="soundbites" to={permalink}>
          <div className="content">
            <span className="part">{currentPart}</span>
            <span className="title">{currentSoundbite.title}</span>
          </div>
          <div className="soundbite-icon">{SmallIcon && <SmallIcon />}</div>
        </ProgressCardWrapper>
      );
    }

    if (stage === 'study') {
      const currentPart = chapter.partChapterLabel;
      const Icon = chapterStudyIcons[progress.currentStudyMode]?.on;

      return (
        <ProgressCardWrapper kind={progress.currentStudyMode} to={permalink}>
          <div className="content">
            <span className="part">{currentPart}</span>
            <span className="title">{chapter.title}</span>
          </div>
          <div className="study-icon">{Icon === null ? null : <Icon />}</div>
        </ProgressCardWrapper>
      );
    }
  }

  //   return (
  //     <ChapterCardWrapper>
  //       <Link to={permalink} className="chapter">
  //         <div className="part-chapter">{chapter.partChapterLabel}</div>
  //         <div className="title-duration">
  //           <div className="title">
  //             <MarkSimple source={chapter.title} />
  //           </div>
  //           <div className="duration">{chapter.clockLikeDuration}</div>
  //         </div>
  //       </Link>
  //       <Link className="crumbs" to={permalink}>
  //         <ChapterBreadcrumbs
  //           breadcrumbState={chapter.currentPoint?.iteration || 1}
  //           compact
  //         />
  //       </Link>
  //       <div className="actions">
  //         <ChapterButtons chapter={chapter} viaDetail={viaDetail} />
  //       </div>
  //     </ChapterCardWrapper>
  //   );
  // }
);
